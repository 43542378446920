import { lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import Loadable from './Loadable';
import { withPermission } from 'contexts/RouterWithPermission';

const SynthesizeRequests = withPermission(Loadable(lazy(() => import('views/HK_Trading/ImportManagement/SynthesizeRequests'))), "HkGroup.HKTrading.QuanLyNhapHang.TongHopDonYeuCauNhap");
const DetailedImportPlant = withPermission(Loadable(lazy(() => import('views/HK_Trading/ImportManagement/SupSynthesisPlanDetail'))), "HkGroup.HKTrading.QuanLyNhapHang.KeHoachNhapChiTiet");
const GeneralImportPlan = withPermission(Loadable(lazy(() => import('views/HK_Trading/ImportManagement/GeneralImportPlan'))), "HkGroup.HKTrading.QuanLyNhapHang.KeHoachNhapTongHop");
const GeneralImportPlanDetails = withPermission(Loadable(lazy(() => import('views/HK_Trading/ImportManagement/GeneralImportPlan/Details'))), "HkGroup.HKTrading.QuanLyNhapHang.KeHoachNhapTongHop");
const DetailedImportPlantDetail = withPermission(Loadable(lazy(() => import('views/HK_Trading/ImportManagement/SupSynthesisPlanDetail/Detail'))), "HkGroup.HKTrading.QuanLyNhapHang.KeHoachNhapChiTiet");

// const GeneralImportPlanCreate = withPermission(Loadable(lazy(() => import('views/HK_Trading/ImportManagement/GeneralImportPlan/Create'))), "HkGroup.HKTrading.QuanLyNhapHang.KeHoachNhapTongHop");
const GeneralImportPlanCreate = withPermission(Loadable(lazy(() => import('views/HK_Trading/ImportManagement/GeneralImportPlan/NewCreate'))), "HkGroup.HKTrading.QuanLyNhapHang.KeHoachNhapTongHop");


const DetailedPlan = Loadable(lazy(() => import('views/HK_Trading/Distribution/DetailedPlan')));
const DetailedPlanCreate = Loadable(lazy(() => import('views/HK_Trading/Distribution/DetailedPlan/Create')));
const ImportWarehouse = withPermission(Loadable(lazy(() => import('views/HK_Trading/Warehouse/Import'))), "HkGroup.QuanLyKho.NhapKho");
const DetailedPlanDetails = Loadable(lazy(() => import('views/HK_Trading/Distribution/DetailedPlan/Details')));
const ExportDistributionList = withPermission(Loadable(lazy(() => import('views/HK_Trading/Warehouse/Export/ExportDistribution'))), "HkGroup.QuanLyKho.XuatKho.XuatPhanPhoi");
const ExportDistributionListCreate = Loadable(lazy(() => import('views/HK_Trading/Warehouse/Export/ExportDistribution/Create')));
const ImportRequestsForm = withPermission(Loadable(lazy(() => import('views/HK_Trading/ImportManagement/ImportRequestForm'))), "HkGroup.HKTrading.QuanLyNhapHang.DonYeuCauNhap");
const DetailImportRequestsForm = withPermission(Loadable(lazy(() => import('views/HK_Trading/ImportManagement/ImportRequestForm/Detail'))), "HkGroup.HKTrading.QuanLyNhapHang.DonYeuCauNhap");
const ExportDistributionCreate = withPermission(Loadable(lazy(() => import('views/HK_Trading/Warehouse/Export/ExportDistribution/Create'))), "HkGroup.QuanLyKho.XuatKho.XuatPhanPhoi");
const ExportDistributionDetails = withPermission(Loadable(lazy(() => import('views/HK_Trading/Warehouse/Export/ExportDistribution/Details'))), "HkGroup.QuanLyKho.XuatKho.XuatPhanPhoi");
const ImportWarehouseDetail = withPermission(Loadable(lazy(() => import('views/HK_Trading/Warehouse/Import/Detail'))), "HkGroup.QuanLyKho.NhapKho");
const WarehouseList = withPermission(Loadable(lazy(() => import('views/HK_Trading/Warehouse/Warehouse'))), "HkGroup.QuanLyKho.QuanLyKho");
const Debt = withPermission(Loadable(lazy(() => import('views/HK_Trading/Debt'))), "HkGroup");
const Entry = withPermission(Loadable(lazy(() => import('views/HK_Trading/Entry'))), "HkGroup");
const ManageEntry = withPermission(Loadable(lazy(() => import('views/HK_Trading/Entry/Create'))), "HkGroup");

const HKGroupRoutes: RouteObject = {
  path: 'hk_trading',
  element: <Outlet />,
  children: [
    {
      path: "import-management/import-requests-form",
      children: [{ index: true, element: <ImportRequestsForm /> }],
    },
    {
      path: "import-management/import-requests-form/:id",
      children: [{ index: true, element: <DetailImportRequestsForm /> }],
    },
    {
      path: "import-management/synthesize-requests",
      children: [{ index: true, element: <SynthesizeRequests /> }],
    },
    {
      path: "import-management/general-import-plan",
      children: [{ index: true, element: <GeneralImportPlan /> }],
    },
    {
      path: "import-management/general-import-plan/:id",
      children: [{ index: true, element: <GeneralImportPlanDetails /> }],
    },
    {
      path: "import-management/general-import-plan/create",
      children: [{ index: true, element: <GeneralImportPlanCreate /> }],
    },
    {
      path: 'import-management/sup-synthesis-plan-detail',
      children: [{ index: true, element: <DetailedImportPlant /> }],
    },
    {
      path: '/hk_trading/import-management/sup-synthesis-plan-detail/:id',
      children: [{ index: true, element: <DetailedImportPlantDetail /> }],
    },
    {
      path: '/hk_trading/distribution/detailed_plan',
      children: [{ index: true, element: <DetailedPlan /> }],
    },
    {
      path: '/hk_trading/distribution/detailed_plan/create',
      children: [{ index: true, element: <DetailedPlanCreate /> }],
    },
    {
      path: '/hk_trading/distribution/detailed_plan/:id',
      children: [{ index: true, element: <DetailedPlanDetails /> }],
    },
    {
      path: '/hk_trading/management-warehouse/import-warehouse',
      children: [{ index: true, element: <ImportWarehouse /> }],
    },
    {
      path: '/hk_trading/management-warehouse/import-warehouse/:id',
      children: [{ index: true, element: <ImportWarehouseDetail /> }],
    },
    {
      path: '/hk_trading/management-warehouse/export/export-distribution',
      children: [{ index: true, element: <ExportDistributionList /> }],
    },
    {
      path: '/hk_trading/management-warehouse/export/export-distribution/create',
      children: [{ index: true, element: <ExportDistributionCreate /> }],
    },
    {
      path: '/hk_trading/management-warehouse/export/export-distribution/:id',
      children: [{ index: true, element: <ExportDistributionDetails /> }],
    },
    {
      path: '/hk_trading/management-warehouse/warehouse',
      children: [{ index: true, element: <WarehouseList /> }],
    },
    {
      path: '/hk_trading/debt',
      children: [{ index: true, element: <Debt /> }],
    },
    {
      path: '/hk_trading/entry',
      children: [{ index: true, element: <Entry /> }],
    },
    {
      path: '/hk_trading/entry/create',
      children: [{ index: true, element: <ManageEntry /> }],
    },
    {
      path: '/hk_trading/entry/update/:id',
      children: [{ index: true, element: <ManageEntry /> }],
    },
    {
      path: '/hk_trading/entry/view/:id',
      children: [{ index: true, element: <ManageEntry /> }],
    },
  ],
};

export default HKGroupRoutes;
