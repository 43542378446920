import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Role } from 'types';

export const withPermission = (
  Component: (props: any) => JSX.Element,
  permissionCode: Role,
) => {
  return function () {
    const auth = useSelector((state: RootState) => state.auth);

    if (
      auth?.userRoles === null ||
      (auth?.userRoles !== null &&
        auth?.userRoles.includes(permissionCode))
    ) {
      return <Component />;
    }
    return <Navigate to="/" />;
  };
};
