import axios from 'axios';
import LocalStorage from 'utils/LocalStorage';
import store from 'redux/store';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

axiosClient.interceptors.request.use((config) => {
  const token = LocalStorage.get('accessToken');
  if (token) {
    // @ts-ignore
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response;
    }
    return response;
  },
  async (error) => {
    if (error.response) {
      // Access Token was expired
      if (error.response.status === 401) {
        try {
          store.dispatch({ type: 'auth/logout' });
          LocalStorage.remove('accessToken');
          LocalStorage.remove('refreshToken');
          LocalStorage.remove('persist:root');
        } catch (errors: any) {
          if (errors.response && errors.response.data) {
            return Promise.reject(errors.response.data);
          }
          LocalStorage.remove('accessToken');
          LocalStorage.remove('refreshToken');
          window.location.reload();
          return Promise.reject(errors);
        }
      } else if (error.response.status === 403) {
        window.localStorage.clear();
        window.location.href = '/login';
        return;
      } else if (error.response.data) {
        return Promise.reject(error.response.data);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosClient;
