const breadcrumbConfig = (tennant: string) => {
  return [
    // hk group
    {
      breadcrumb: 'HK Group',
      path: '/hk_group',
    },
    {
      breadcrumb: 'Thông tin điểm bán',
      path: '/hk_group/tenant',
    },
    {
      breadcrumb: 'Quản trị người dùng',
      path: '/hk_group/operate',
    },
    // hk group product
    {
      exact: true,
      breadcrumb: 'Quản lý sản phẩm',
      path: '/hk_group/product',
    },
    {
      breadcrumb: 'Loại sản phẩm',
      path: '/hk_group/product/type',
    },
    {
      breadcrumb: 'Danh sách sản phẩm',
      path: '/hk_group/product/list',
    },
    {
      breadcrumb: 'Chi tiết sản phẩm',
      path: '/hk_group/product/list/:id',
    },
    {
      breadcrumb: 'Nhóm sản phẩm',
      path: '/hk_group/product/type/product_group',
    },
    {
      breadcrumb: 'Chi tiết nhóm sản phẩm',
      path: '/hk_group/product/type/product_group/:id',
    },
    {
      breadcrumb: 'Nhóm điều trị',
      path: '/hk_group/product/type/treatment_group',
    },
    {
      breadcrumb: 'Loại bệnh lý',
      path: '/hk_group/product/type/pathological',
    },
    {
      breadcrumb: 'Chi tiết nhóm điều trị',
      path: '/hk_group/product/type/treatment_group/:id',
    },
    {
      breadcrumb: 'Dạng dùng',
      path: '/hk_group/product/type/usage',
    },
    {
      breadcrumb: 'Chi tiết dạng dùng',
      path: '/hk_group/product/type/usage/:id',
    },
    {
      breadcrumb: 'Đơn vị đo lường',
      path: '/hk_group/product/measure',
    },
    {
      breadcrumb: 'Chi tiết đơn vị đo lường',
      path: '/hk_group/product/measure/:id',
    },
    {
      breadcrumb: 'Nhà cung cấp',
      path: '/hk_group/product/supplier',
    },
    {
      breadcrumb: 'Chi tiết nhà cung cấp',
      path: '/hk_group/product/supplier/:id',
    },
    {
      breadcrumb: 'Giá tham chiếu',
      path: '/hk_group/product/reference_prices',
    },
    // hk group operate
    {
      breadcrumb: 'Danh sách người dùng',
      path: '/hk_group/operate/users',
    },
    {
      breadcrumb: 'Tạo mới người dùng',
      path: '/hk_group/operate/users/create',
    },
    {
      breadcrumb: 'Xem chi tiết người dùng',
      path: '/hk_group/operate/users/view/:id',
    },
    {
      breadcrumb: 'Chỉnh sửa người dùng',
      path: '/hk_group/operate/users/update/:id',
    },
    {
      breadcrumb: 'Phân quyền',
      path: '/hk_group/operate/roles',
    },
    //HK group Customer

    { breadcrumb: 'Danh sách khách hàng', path: '/hk_group/customer' },
    {
      breadcrumb: 'Tạo mới khách hàng',
      path: '/hk_group/customer/create',
    },
    {
      breadcrumb: 'Xem chi tiết khách hàng',
      path: '/hk_group/customer/view/:id',
    },
    {
      breadcrumb: 'Chỉnh sửa khách hàng',
      path: '/hk_group/customer/update/:id',
    },
    {
      breadcrumb: 'Báo cáo',
      path: '/hk_group/sales_statistical',
    },
    {
      breadcrumb: 'Báo cáo doanh thu',
      path: '/hk_group/sales_statistical/sales_report',
    },
    {
      breadcrumb: 'Báo cáo nhập tồn kho',
      path: '/hk_group/sales_statistical/inventory_report',
    },
    {
      breadcrumb: 'Báo cáo hàng cận date',
      path: '/hk_group/sales_statistical/search_near_date',
    },
    {
      breadcrumb: 'Báo cáo doanh thu theo giờ',
      path: '/hk_group/sales_statistical/inventorybyhours',
    },
    {
      breadcrumb: 'Báo cáo doanh thu theo sản phẩm',
      path: '/hk_group/sales_statistical/report_inventory_by_product',
    },
    {
      breadcrumb: 'Báo cáo doanh thu theo nhóm sản phẩm',
      path: '/hk_group/sales_statistical/report_inventory_group_product',
    },
    {
      breadcrumb: 'Báo cáo doanh thu theo kỳ',
      path: '/hk_group/sales_statistical/inventorybyperiod',
    },
    {
      breadcrumb: 'Báo cáo thu chi điểm bán',
      path: '/hk_group/sales_statistical/daily-expenses-report',
    },
    {
      breadcrumb: 'Báo cáo lãi lỗ',
      path: '/hk_group/sales_statistical/profit-and-loss-report',
    },
    // hk care
    { breadcrumb: tennant, path: '/hk_care' },
    { breadcrumb: 'Danh sách sản phẩm', path: '/hk_care/product/list' },
    { breadcrumb: 'Đăng ký sản phẩm', path: '/hk_care/product/list/create' },
    // hk care warehouse
    { breadcrumb: 'Quản lý kho', path: '/hk_care/warehouse' },
    { breadcrumb: 'Phân quyền', path: '/hk_care/operate/roles' },
    { breadcrumb: 'Yêu cầu nhập hàng', path: '/hk_care/warehouse/request' },
    {
      breadcrumb: 'Tạo  mới yêu cầu nhập hàng',
      path: '/hk_care/warehouse/request/create',
    },
    {
      breadcrumb: 'Xem chi tiết yêu cầu nhập hàng',
      path: '/hk_care/warehouse/request/:id',
    },
    {
      breadcrumb: 'Kiểm kê kho',
      path: '/hk_care/warehouse/inventory_record',
    },
    {
      breadcrumb: 'Tạo mới biên bản kiểm kê kho',
      path: '/hk_care/warehouse/inventory_record/create',
    },
    {
      breadcrumb: 'Xem chi tiết biên bản kiểm kê kho',
      path: '/hk_care/warehouse/inventory_record/view/:id',
    },
    {
      breadcrumb: 'Chỉnh sửa biên bản kiểm kê kho',
      path: '/hk_care/warehouse/inventory_record/update/:id',
    },
    {
      breadcrumb: 'Nhập kho',
      path: '/hk_care/warehouse/import/receipt',
    },
    {
      breadcrumb: 'Xem chi tiết hóa đơn nhập kho',
      path: '/hk_care/warehouse/import/receipt/:id',
    },
    {
      breadcrumb: 'Tạo mới hóa đơn nhập kho',
      path: '/hk_care/warehouse/import/receipt/create',
    },
    {
      breadcrumb: 'Chỉnh sửa hóa đơn nhập kho',
      path: '/hk_care/warehouse/import/receipt/:id/update',
    },
    {
      breadcrumb: 'Thêm hóa đơn',
      path: '/hk_care/warehouse/import/receipt/create',
    },
    { breadcrumb: 'Xuất kho', path: '/hk_care/warehouse/export' },
    { breadcrumb: 'Xuất hủy', path: '/hk_care/warehouse/export/cancel' },
    {
      breadcrumb: 'Tạo mới hóa đơn xuất hủy',
      path: '/hk_care/warehouse/export/cancel/create',
    },
    {
      breadcrumb: 'Xem chi tiết hóa đơn xuất hủy',
      path: '/hk_care/warehouse/export/cancel/view/:id',
    },
    {
      breadcrumb: 'Xuất luân chuyển',
      path: '/hk_care/warehouse/export/circulation_invoice',
    },
    {
      breadcrumb: 'Tạo mới hoá đơn xuất luân chuyển',
      path: '/hk_care/warehouse/export/circulation_invoice/create',
    },
    {
      breadcrumb: 'Xem chi tiết hoá đơn xuất luân chuyển',
      path: '/hk_care/warehouse/export/circulation_invoice/view/:id',
    },
    {
      breadcrumb: 'Xem chi tiết hoá đơn xuất luân chuyển',
      path: '/hk_care/warehouse/export/circulation_invoice/update/:id',
    },
    {
      exact: true,
      breadcrumb: 'Cập nhật hóa đơn xuất hủy',
      path: '/hk_care/warehouse/export/cancel/update/:id',
    },
    {
      exact: true,
      breadcrumb: 'Báo cáo',
      path: '/hk_care/sales_statistical',
    },
    {
      exact: true,
      breadcrumb: 'Báo cáo doanh thu',
      path: '/hk_care/sales_statistical/sales_report',
    },
    {
      exact: true,
      breadcrumb: 'Báo cáo hàng cận date',
      path: '/hk_care/sales_statistical/search_near_date',
    },
    {
      exact: true,
      breadcrumb: 'Báo cáo nhập tồn kho',
      path: '/hk_care/sales_statistical/inventory_report',
    },
    {
      exact: true,
      breadcrumb: 'Báo cáo doanh thu theo giờ',
      path: '/hk_care/sales_statistical/inventorybyhours',
    },
    {
      exact: true,
      breadcrumb: 'Báo cáo doanh thu theo sản phẩm',
      path: '/hk_care/sales_statistical/report_inventory_by_product',
    },
    {
      exact: true,
      breadcrumb: 'Báo cáo doanh thu theo nhóm sản phẩm',
      path: '/hk_care/sales_statistical/report_inventory_group_product',
    },
    {
      exact: true,
      breadcrumb: 'Báo cáo doanh thu theo kỳ',
      path: '/hk_care/sales_statistical/inventorybyperiod',
    },
    {
      exact: true,
      breadcrumb: 'Báo cáo thu chi trong ngày',
      path: '/hk_care/sales_statistical/daily_expenses',
    },
    {
      exact: true,
      breadcrumb: 'Báo cáo lãi lỗ',
      path: '/hk_care/sales_statistical/profit-and-loss-report',
    },
    {
      exact: true,
      breadcrumb: 'Quản lý vận hành',
      path: '/hk_care/operate',
    },
    {

      breadcrumb: 'Danh Sách Lương Thưởng, phạt',
      path: '/hk_care/operate/accountant',
    },
    {

      breadcrumb: 'Thêm Mới',
      path: '/hk_care/operate/accountant/create',
    },
    {
      exact: true,
      breadcrumb: 'Danh sách nhân viên',
      path: '/hk_care/operate/staff',
    },
    {
      breadcrumb: 'Tạo mới nhân viên',
      path: '/hk_care/operate/staff/create',
    },
    {
      breadcrumb: 'Xem chi tiết nhân viên',
      path: '/hk_care/operate/staff/view/:id',
    },
    {
      breadcrumb: 'Chỉnh sửa nhân viên',
      path: '/hk_care/operate/staff/update/:id',
    },
    // hk care sales
    { breadcrumb: 'Quản lý bán hàng', path: '/hk_care/sales' },
    { breadcrumb: 'Danh sách hóa đơn', path: '/hk_care/sales/order' },

    { breadcrumb: 'Danh sách khách hàng', path: '/hk_care/customer' },
    {
      breadcrumb: 'Tạo mới khách hàng',
      path: '/hk_care/customer/create',
    },
    {
      breadcrumb: 'Xem chi tiết khách hàng',
      path: '/hk_care/customer/view/:id',
    },
    {
      breadcrumb: 'Chỉnh sửa khách hàng',
      path: '/hk_care/customer/update/:id',
    },
    {
      breadcrumb: 'Xem chi tiết hoá đơn bán hàng',
      path: '/hk_care/sales/order/:id',
    },
    {
      breadcrumb: 'Trả hàng',
      path: '/hk_care/sales/return-merchandise',
    },
    // hk trading
    {
      breadcrumb: 'HK Trading',
      path: '/hk_trading',
    },
    {
      breadcrumb: 'Quản lý nhập hàng',
      path: '/hk_trading/import-management',
    },
    {
      breadcrumb: 'Tổng hợp yêu cầu nhập',
      path: '/hk_trading/import-management/synthesize-requests',
    },
    {
      breadcrumb: 'Kế hoạch nhập tổng hợp',
      path: '/hk_trading/import-management/general-import-plan',
    },
    {
      breadcrumb: 'Tạo mới kế hoạch nhập tổng hợp',
      path: '/hk_trading/import-management/general-import-plan/create',
    },
    {
      breadcrumb: 'Kế hoạch nhập chi tiết',
      path: '/hk_trading/import-management/sup-synthesis-plan-detail',
    },
    {
      breadcrumb: 'Xem chi tiết kế hoạch nhập chi tiết',
      path: '/hk_trading/import-management/sup-synthesis-plan-detail/:id',
    },
    {
      breadcrumb: 'Quản lý phân phối',
      path: '/hk_trading/distribution',
    },
    {
      breadcrumb: 'Kế hoạch phân phối chi tiết',
      path: '/hk_trading/distribution/detailed_plan',
    },
    {
      breadcrumb: 'Thêm mới kế hoạch phân phối chi tiết',
      path: '/hk_trading/distribution/detailed_plan/create',
    },
    {
      breadcrumb: 'Xem chi tiết kế hoạch phân phối chi tiết',
      path: '/hk_trading/distribution/detailed_plan/:id',
    },
    {
      breadcrumb: 'Quản lý kho',
      path: '/hk_trading/management-warehouse',
    },
    {
      breadcrumb: 'Nhập kho',
      path: '/hk_trading/management-warehouse/import-warehouse',
    },
    {
      breadcrumb: 'Xem chi tiết hoá đơn nhập kho',
      path: '/hk_trading/management-warehouse/import-warehouse/:id',
    },
    {
      breadcrumb: 'Xuất kho',
      path: '/hk_trading/management-warehouse/export',
    },
    {
      breadcrumb: 'Xuất phân phối',
      path: '/hk_trading/management-warehouse/export/export-distribution',
    },
    {
      breadcrumb: 'Tạo mới hóa đơn xuất phân phối',
      path: '/hk_trading/management-warehouse/export/export-distribution/create',
    },
    {
      breadcrumb: 'Đơn yêu cầu nhập',
      path: '/hk_trading/import-management/import-requests-form',
    },
    {
      breadcrumb: 'Xem chi tiết đơn yêu cầu',
      path: '/hk_trading/import-management/import-requests-form/:id',
    },
    {
      breadcrumb: 'Xem chi tiết hoá đơn xuất phân phối',
      path: '/hk_trading/management-warehouse/export/export-distribution/:id',
    },
    {
      breadcrumb: 'Kho hàng',
      path: '/hk_trading/management-warehouse/warehouse',
    },
    {
      breadcrumb: 'Quản lý công nợ',
      path: '/hk_trading/debt',
    },
    {
      breadcrumb: 'Quản lý thu chi',
      path: '/hk_trading/entry',
    },
    {
      breadcrumb: 'Thêm mới',
      path: '/hk_trading/entry/create',
    },
    {
      breadcrumb: 'Chi tiết',
      path: '/hk_trading/entry/view',
    },
    {
      breadcrumb: 'Chỉnh sửa',
      path: '/hk_trading/entry/update/:id',
    },
  ];
};

export default breadcrumbConfig;
