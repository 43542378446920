import axiosClient from 'api';
import { baseURL } from 'config';
import { FilterParams } from 'types';
import DateFns from 'utils/DateFns';

class ProductService {
  getSearchProductList(searchText: string) {
    return axiosClient.get(`${baseURL}/SearchProductSale/Search`, {
      params: {
        productName: searchText,
      },
    });
  }

  getProductList(productName: string) {
    return axiosClient.get(`${baseURL}/SearchProductSale/Search`, {
      params: {
        productName,
      },
    });
  }

  getSaleProduct(productId: number) {
    return axiosClient.get(`${baseURL}/SearchProductSale/SaleProduct`, {
      params: {
        productId,
      },
    });
  }

  getAll({
    pageIndex,
    pageSize,
    searchText,
    startDate,
    lastDate,
    userId
  }: FilterParams) {
    return axiosClient.get(`${baseURL}/BillOfSale/SearchAll`, {
      params: {
        SkipCount: (pageIndex - 1) * pageSize,
        MaxResultCount: pageSize,
        Keyword: searchText,
        startDate: startDate
          ? DateFns.format(startDate, 'yyyy-MM-dd') + ' 00:00'
          : '',
        lastDate: lastDate
          ? DateFns.format(lastDate, 'yyyy-MM-dd') + ' 23:59'
          : '',
        userId:userId
      },
    });
  }
}

export default new ProductService();
