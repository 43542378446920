import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface Props {
  title: string;
  content: string;
  width?: number;
}

const Figure = (props: Props) => {
  const { title, content, width = 100 } = props;
  return (
    <Stack direction="row">
      <Typography variant="body1" sx={{ fontWeight: 'medium', width }}>
        {title}
      </Typography>
      <Typography variant="body1">{content}</Typography>
    </Stack>
  );
};

export default Figure;
