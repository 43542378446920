import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import cashClosingService from 'services/cashClosing.service';

interface typeFilters {
  [key: string]: any;
}
interface IInitialState {
  openCashClosing: boolean;
  openConfirmShiftClosing: boolean;
  openShiftHandover: boolean;
  openShiftHandoverConfirmation: boolean;
  idUpdate: string;
  idMessage: string;
  isUpdateMessage: boolean;
}
const initialState: IInitialState = {
  openCashClosing: false,
  openConfirmShiftClosing: false,
  openShiftHandover: false,
  openShiftHandoverConfirmation: false,
  idUpdate: '',
  idMessage: '',
  isUpdateMessage: false,
};

// lấy dữ liệu chốt ca
export const getDataClosingWorkingAsnyc = createAsyncThunk(
  'cashClosing/closingWorkingAsnyc',
  async (filters: typeFilters, { rejectWithValue }) => {
    try {
      const { data } = await cashClosingService.getClosingWorkingAsnyc(filters);

      if (data.items) {
        const list = data.items;
        const total = data.totalCount;

        return {
          list,
          total,
        };
      }

      return rejectWithValue('Get data fail');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// kiểm tra đã chốt ca hay chưa
export const checkDisplayConfirmDialog = createAsyncThunk(
  'cashClosing/checkDisplayConfirmDialog',
  async () => {
    try {
      const { data } = await cashClosingService.checkCashClosing();

      if (data) {
        return {
          display: data.data || 0,
        };
      }
    } catch (error) {}
  }
);

// tạo phiếu chi cho ca sau
export const createOrUpdateClosingWork = createAsyncThunk(
  'cashClosing/createOrUpdateClosingWork',
  async (payload: typeFilters, { rejectWithValue }) => {
    try {
      await cashClosingService.createUpdateClosingWork(payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createFinanceCheckClosingWork = createAsyncThunk(
  'cashClosing/createFinanceCheckClosingWork',
  async (payload: typeFilters, { rejectWithValue }) => {
    try {
      await cashClosingService.createFinanceCheckClosingWork(payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkFinanceByClosingWorkAsnyc = createAsyncThunk(
  'cashClosing/checkFinanceByClosingWorkAsnyc',
  async (payload: number, { rejectWithValue }) => {
    try {
      await cashClosingService.checkFinanceByClosingWorkAsnyc(payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const cashClosingSlice = createSlice({
  name: 'cashClosing',
  initialState,
  reducers: {
    handleOpenCashClosing: (state) => {
      state.openCashClosing = true;
    },
    handleCloseCashClosing: (state) => {
      state.openCashClosing = false;
    },
    handleOpenConfirmShiftClosing: (state) => {
      state.openConfirmShiftClosing = true;
    },
    handleCloseConfirmShiftClosing: (state) => {
      state.openConfirmShiftClosing = false;
    },
    checkConfirmShiftClosing: (state) => {
      state.openConfirmShiftClosing = false;
      state.openCashClosing = true;
    },
    handleCloseSiftHandover: (state) => {
      state.openShiftHandover = false;
    },
    handleOpenShiftHandover: (state) => {
      state.openCashClosing = false;
      state.openShiftHandover = true;
    },
    handleBackShiftHandover: (state) => {
      state.openCashClosing = true;
      state.openShiftHandover = false;
    },
    handleOpenShiftHandoverConfirmation: (state, action) => {
      state.openShiftHandoverConfirmation = true;
      state.idUpdate = action.payload.idUpdate;
      state.idMessage = action.payload.idMessage;
    },
    handleCloseShiftHandoverConfirmation: (state) => {
      state.openShiftHandoverConfirmation = false;
    },
    checkUpdateMessage: (state) => {
      state.isUpdateMessage = !state.isUpdateMessage;
    },
  },
  extraReducers: (builder) => {},
});
export const {
  handleOpenCashClosing,
  handleCloseCashClosing,
  handleOpenConfirmShiftClosing,
  handleCloseConfirmShiftClosing,
  checkConfirmShiftClosing,
  handleCloseSiftHandover,
  handleOpenShiftHandover,
  handleBackShiftHandover,
  handleOpenShiftHandoverConfirmation,
  handleCloseShiftHandoverConfirmation,
  checkUpdateMessage,
} = cashClosingSlice.actions;

export default cashClosingSlice.reducer;
