export const ObjectType = [
  {
    id: 2,
    name: 'Nhân viên'
  },
  {
    id: 1,
    name: 'Admin điểm bán'
  },
  {
    id: 3,
    name: 'Khác'
  },
]

export const TicketType = [
  {
    id: 2,
    name: 'Phiếu thu'
  },
  {
    id: 1,
    name: 'Phiếu chi'
  },
]

export const EntryType = [
  {
    id: 1,
    name: 'Tiên thuê nhà'
  },
  {
    id: 2,
    name: 'Chi phí thuê bằng dược sĩ'
  },
  {
    id: 3,
    name: 'Tiền lương'
  },
  {
    id: 5,
    name: 'Chi phí internet'
  },
  {
    id: 4,
    name: 'Chi phí điện, nước'
  },
  {
    id: 6,
    name: 'Hệ thống phần mềm'
  },
  {
    id: 7,
    name: 'Chi phí khác'
  },
]