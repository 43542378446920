import { Button, ButtonProps } from '@mui/material';
import React, { useMemo } from 'react';

function ButtonTag<C extends React.ElementType>(
  props: ButtonProps<C, { component?: C }>
) {
  const { children, sx, color, ...rest } = props;

  const getColor = useMemo(() => {
    if (!color) return '#00AB55';
    if (color === 'primary') return '#00AB55';
    if (color === 'secondary') return '#9c27b0';
    if (color === 'success') return '#2e7d32';
    if (color === 'error') return '#d32f2f';
    if (color === 'info') return '#0288d1';
    if (color === 'warning') return '#ed6c02';
    return '#00AB55';
  }, [color]);

  return (
    <Button
      sx={{
        cursor: 'default',
        ...sx,
        '&.MuiButtonBase-root:hover': {
          backgroundColor: getColor + ' !important',
        },
        minWidth: '120px',
      }}
      color={color}
      {...rest}
      disableRipple
    >
      {children}
    </Button>
  );
}

export default ButtonTag;
