import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IOTP, IPassword, IProfile, ISendOTP, IUpdatePassword } from 'interface';
import profileService from 'services/profile.service';

interface IInitialState { }

const initialState: IInitialState = {};

export const getProfile = createAsyncThunk(
  'profile/getProfile',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await profileService.getProfile();

      if (data) {
        const profile = data;

        return {
          profile,
        };
      }

      return rejectWithValue('Get data fail');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProfile = createAsyncThunk(
  'profile/update',
  async (payload: IProfile, { rejectWithValue }) => {
    try {
      await profileService.update(payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  'profile/changePassword',
  async (payload: IPassword, { rejectWithValue }) => {
    try {
      await profileService.changePassword(payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const sendOtp = createAsyncThunk(
  'profile/sendOtp',
  async (payload: ISendOTP, { rejectWithValue }) => {
    try {
      const res = await profileService.sendOtp(payload);
      if (res) {
        const response = res.data;

        return {
          response,
          status: 200
        };
      }

    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkOtp = createAsyncThunk(
  'profile/checkOtp',
  async (payload: IOTP, { rejectWithValue }) => {
    try {
      const res = await profileService.checkOtp(payload);
      if (res) {
        const response = res.data;

        return {
          response,
        };
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePasswordWithOTP = createAsyncThunk(
  'profile/checkOtp',
  async (payload: IUpdatePassword, { rejectWithValue }) => {
    try {
      const res = await profileService.updatePasswordWithOtp(payload);
      if (res) {
        const response = res.data;

        return {
          response,
          status: 200
        };
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => { },
});

export default profileSlice.reducer;
