import { lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import Loadable from './Loadable';
import { withPermission } from 'contexts/RouterWithPermission';

const SalesOrder = withPermission(Loadable(lazy(() => import('views/HK_Care/Sales/Order'))), "HkSalePoint.QuanLyBanHang.DanhSachHoaDon");
const SalesOrderDetail = withPermission(Loadable(lazy(() => import('views/HK_Care/Sales/Order/Details'))), "HkSalePoint.QuanLyBanHang.DanhSachHoaDon");
const ImportReceipt = withPermission(Loadable(lazy(() => import('views/HK_Care/Warehouse/Import'))), "HkSalePoint.QuanLyKho.NhapKho");
const CreateImportReceipt = withPermission(Loadable(lazy(() => import('views/HK_Care/Warehouse/Import/Receipt/Create'))), "HkSalePoint.QuanLyKho.NhapKho");
const ImportReceiptDetail = withPermission(Loadable(lazy(() => import('views/HK_Care/Warehouse/Import/Receipt/Details'))), "HkSalePoint.QuanLyKho.NhapKho");
const ExportCancel = withPermission(Loadable(lazy(() => import('views/HK_Care/Warehouse/Export/Cancel'))), "HkSalePoint.QuanLyKho.XuatKho.XuatHuy");
const CreateExportCancel = withPermission(Loadable(lazy(() => import('views/HK_Care/Warehouse/Export/Cancel/Create'))), "HkSalePoint.QuanLyKho.XuatKho.XuatHuy");
const ExportCancelDetail = withPermission(Loadable(lazy(() => import('views/HK_Care/Warehouse/Export/Cancel/Details'))), "HkSalePoint.QuanLyKho.XuatKho.XuatHuy");
const CirculationInvoice = withPermission(Loadable(lazy(() => import('views/HK_Care/Warehouse/Export/CirculationInvoice'))), "HkSalePoint.QuanLyKho.XuatKho.XuatLuanChuyen");
const CreateCirculationInvoice = withPermission(Loadable(lazy(() => import('views/HK_Care/Warehouse/Export/CirculationInvoice/Create'))), "HkSalePoint.QuanLyKho.XuatKho.XuatLuanChuyen");
const CirculationInvoiceDetail = withPermission(Loadable(lazy(() => import('views/HK_Care/Warehouse/Export/CirculationInvoice/Details'))), "HkSalePoint.QuanLyKho.XuatKho.XuatLuanChuyen");
const ProductList = withPermission(Loadable(lazy(() => import('views/HK_Care/Product/ProductList'))), "HkSalePoint.DanhSachSanPham");
const ProductListCreate = withPermission(Loadable(lazy(() => import('views/HK_Care/Product/ProductList/Create'))), "HkSalePoint.DanhSachSanPham");
// const ProductDetails = withPermission(Loadable(lazy(() => import('views/HK_Care/Product/ProductList/Details'))), "");
const ProductDetails = withPermission(Loadable(lazy(() => import('views/HK_Care/Product/ProductList/DetailsNew'))), "HkSalePoint.DanhSachSanPham");
const RequestImport = withPermission(Loadable(lazy(() => import('views/HK_Care/Warehouse/Request'))), "HkSalePoint.QuanLyKho.YeuCauNhapHang");
const CreateRequestImport = withPermission(Loadable(lazy(() => import('views/HK_Care/Warehouse/Request/Create'))), "HkSalePoint.QuanLyKho.YeuCauNhapHang");
const RequestImportDetails = withPermission(Loadable(lazy(() => import('views/HK_Care/Warehouse/Request/Details'))), "HkSalePoint.QuanLyKho.YeuCauNhapHang");
const RequestImportEdit = withPermission(Loadable(lazy(() => import('views/HK_Care/Warehouse/Request/Edit'))), "HkSalePoint.QuanLyKho.YeuCauNhapHang");
const InventoryRecord = withPermission(Loadable(lazy(() => import('views/HK_Care/Warehouse/InventoryRecord'))), "HkSalePoint.QuanLyKho.KiemKeKho");
const CreateInventoryRecord = withPermission(Loadable(lazy(() => import('views/HK_Care/Warehouse/InventoryRecord/Create'))), "HkSalePoint.QuanLyKho.KiemKeKho");
// const Roles = Loadable(lazy(() => import('views/HK_Care/Operate/Roles')));
const Roles = withPermission(Loadable(lazy(() => import('views/HK_Care/Operate/RolePermission'))), "HkSalePoint.QuanLyVanHanh.PhanQuyen");
const NotifyView = Loadable(lazy(() => import('views/HK_Care/NotifyView')));
const SalesReport = withPermission(Loadable(lazy(() => import('views/HK_Care/SalesStatistical/SalesReport'))), "HkSalePoint.BaoCao.BaoCaoDoanhThuTheoNhanVien");
const InventoryReport = withPermission(Loadable(lazy(() => import('views/HK_Care/SalesStatistical/InventoryReport'))), "HkSalePoint.BaoCao.BaoCaoNhapTonKho");
const SearchNearDateReport = withPermission(Loadable(lazy(() => import('views/HK_Care/SalesStatistical/SearchNearDateReport'))), "HkSalePoint.BaoCao.BaoCaoCanDate");
const OperateAccountant = withPermission(Loadable(lazy(() => import('views/HK_Care/Operate/Accountant'))), "HkSalePoint.QuanLyVanHanh.DanhSachNhanVien");
const CreateOperateAccountant = withPermission(Loadable(lazy(() => import('views/HK_Care/Operate/Accountant/Create'))), "HkSalePoint.QuanLyVanHanh.DanhSachNhanVien");
const OperateStaff = withPermission(Loadable(lazy(() => import('views/HK_Care/Operate/Staff'))), "HkSalePoint.QuanLyVanHanh.DanhSachNhanVien");
const CreateOperateStaff = withPermission(Loadable(lazy(() => import('views/HK_Care/Operate/Staff/Create'))), "HkSalePoint.QuanLyVanHanh.DanhSachNhanVien");
const ReqImportWarehouseHkTrading = withPermission(Loadable(lazy(() => import('views/HK_Care/Warehouse/Import/RequestHKTranding/Detail'))), "HkSalePoint.QuanLyKho.NhapKho");
const Customers = withPermission(Loadable(lazy(() => import('views/HK_Care/Sales/Customer'))), "HkSalePoint.QuanLyKhachHang");
const Entry = Loadable(lazy(() => import('views/HK_Care/Manage/Entry')));
const ManageEntry = Loadable(lazy(() => import('views/HK_Care/Manage/Entry/Create')));
const CreateSaleCustomers = withPermission(Loadable(lazy(() => import('views/HK_Care/Sales/Customer/Create'))), "HkSalePoint.QuanLyKhachHang");
const ViewSaleCustomers = withPermission(Loadable(lazy(() => import('views/HK_Care/Sales/Customer/Detail'))), "HkSalePoint.QuanLyKhachHang");
const InventoryByHours = withPermission(Loadable(lazy(() => import('views/HK_Care/SalesStatistical/InventoryByHours'))), "HkSalePoint.BaoCao.BaoCaoTheoGio");
const ReportInventoryByProduct = withPermission(Loadable(lazy(() => import('views/HK_Care/SalesStatistical/ReportInventoryByProduct'))), "HkSalePoint.BaoCao.BaoCaoTheoSanPham");
const ReportInventoryGroupProduct = withPermission(Loadable(lazy(() => import('views/HK_Care/SalesStatistical/ReportInventoryGroupProduct'))), "HkSalePoint.BaoCao.BaoCaoTheoNhomSanPham");
const ReportInventoryByPeriod = withPermission(Loadable(lazy(() => import('views/HK_Care/SalesStatistical/InventoryByPeriod'))), "HkSalePoint.BaoCao.BaoCaoTheoKy");
const ReturnMerchandise = withPermission(Loadable(lazy(() => import('views/HK_Care/Sales/ReturnMerchandise'))), "HkSalePoint.QuanLyBanHang");
const ReportDailyExpenses = withPermission(Loadable(lazy(() => import('views/HK_Care/Report/DailyExpenses'))), "HkSalePoint.BaoCao");
const ProfitAndLossReport = withPermission(Loadable(lazy(() => import('views/HK_Care/SalesStatistical/ProfitAndLossReport'))), "HkSalePoint.BaoCao");

const HKCareRoutes: RouteObject = {
  path: 'hk_care',
  element: <Outlet />,
  children: [
    {
      path: 'sales/order',
      children: [{ index: true, element: <SalesOrder /> }],
    },
    {
      path: 'sales/order/:id',
      children: [{ index: true, element: <SalesOrderDetail /> }],
    },
    {
      path: 'customer',
      children: [{ index: true, element: <Customers /> }],
    },
    {
      path: 'customer/create',
      children: [{ index: true, element: <CreateSaleCustomers /> }],
    },
    {
      path: 'customer/update/:id',
      children: [{ index: true, element: <ViewSaleCustomers /> }],
    },
    {
      path: 'customer/view/:id',
      children: [{ index: true, element: <ViewSaleCustomers /> }],
    },

    {
      path: 'entry',
      children: [{ index: true, element: <Entry /> }],
    },
    {
      path: 'entry/create',
      children: [{ index: true, element: <ManageEntry /> }],
    },
    {
      path: 'entry/update/:id',
      children: [{ index: true, element: <ManageEntry /> }],
    },
    {
      path: 'entry/view/:id',
      children: [{ index: true, element: <ManageEntry /> }],
    },
    {
      path: 'warehouse/import/receipt',
      children: [{ index: true, element: <ImportReceipt /> }],
    },
    {
      path: 'warehouse/import/receipt/create',
      children: [{ index: true, element: <CreateImportReceipt /> }],
    },
    {
      path: 'warehouse/import/receipt/:id',
      children: [{ index: true, element: <ImportReceiptDetail /> }],
    },
    {
      path: 'warehouse/import/receipt/:id/update',
      children: [{ index: true, element: <CreateImportReceipt /> }],
    },
    {
      path: 'warehouse/export/cancel',
      children: [{ index: true, element: <ExportCancel /> }],
    },
    {
      path: 'warehouse/export/cancel/create',
      children: [{ index: true, element: <CreateExportCancel /> }],
    },
    {
      path: 'warehouse/export/cancel/view/:id',
      children: [{ index: true, element: <ExportCancelDetail /> }],
    },
    {
      path: 'warehouse/export/cancel/update/:id',
      children: [{ index: true, element: <CreateExportCancel /> }],
    },
    {
      path: 'warehouse/export/circulation_invoice',
      children: [{ index: true, element: <CirculationInvoice /> }],
    },
    {
      path: 'warehouse/export/circulation_invoice/create',
      children: [{ index: true, element: <CreateCirculationInvoice /> }],
    },
    {
      path: 'warehouse/export/circulation_invoice/view/:id/:exportWHId',
      children: [{ index: true, element: <CirculationInvoiceDetail /> }],
    },
    {
      path: 'warehouse/export/circulation_invoice/update/:id/:exportWHId',
      children: [{ index: true, element: <CreateCirculationInvoice /> }],
    },
    {
      path: 'product/list',
      children: [{ index: true, element: <ProductList /> }],
    },
    {
      path: 'product/list/create',
      children: [{ index: true, element: <ProductListCreate /> }],
    },
    {
      path: 'product/list/:id',
      children: [{ index: true, element: <ProductDetails /> }],
    },
    {
      path: 'warehouse/request',
      children: [{ index: true, element: <RequestImport /> }],
    },
    {
      path: 'warehouse/request/:id',
      children: [{ index: true, element: <RequestImportDetails /> }],
    },
    {
      path: 'warehouse/request/edit/:id',
      children: [{ index: true, element: <RequestImportEdit /> }],
    },
    {
      path: 'warehouse/request/create',
      children: [{ index: true, element: <CreateRequestImport /> }],
    },
    {
      path: 'warehouse/inventory_record',
      children: [{ index: true, element: <InventoryRecord /> }],
    },
    {
      path: 'warehouse/inventory_record/create',
      children: [{ index: true, element: <CreateInventoryRecord /> }],
    },
    {
      path: 'warehouse/inventory_record/update/:id/:v',
      children: [{ index: true, element: <CreateInventoryRecord /> }],
    },
    {
      path: 'warehouse/inventory_record/view/:id/:v',
      children: [{ index: true, element: <CreateInventoryRecord /> }],
    },
    // {
    //   path: 'operate/roles',
    //   children: [{ index: true, element: <Roles /> }],
    // },
    {
      path: 'operate/roles',
      children: [{ index: true, element: <Roles /> }],
    },
    {
      path: 'sales_statistical/sales_report',
      children: [{ index: true, element: <SalesReport /> }],
    },
    {
      path: 'sales_statistical/search_near_date',
      children: [{ index: true, element: <SearchNearDateReport /> }],
    },
    {
      path: 'sales_statistical/inventory_report',
      children: [{ index: true, element: <InventoryReport /> }],
    },
    {
      path: 'operate/accountant',
      children: [{ index: true, element: <OperateAccountant /> }],
    },
    {
      path: 'operate/accountant/create',
      children: [{ index: true, element: <CreateOperateAccountant /> }],
    },
    {
      path: 'operate/staff',
      children: [{ index: true, element: <OperateStaff /> }],
    },
    {
      path: 'operate/staff/create',
      children: [{ index: true, element: <CreateOperateStaff /> }],
    },
    {
      path: 'operate/staff/update/:id',
      children: [{ index: true, element: <CreateOperateStaff /> }],
    },
    {
      path: 'operate/staff/view/:id',
      children: [{ index: true, element: <CreateOperateStaff /> }],
    },
    {
      path: 'warehouse/import/receipt/detail-req-hkTrading/:id',
      children: [{ index: true, element: <ReqImportWarehouseHkTrading /> }],
    },
    {
      path: 'notifyview',
      children: [{ index: true, element: <NotifyView /> }],
    },
    {
      path: 'sales_statistical/inventorybyhours',
      children: [{ index: true, element: <InventoryByHours /> }],
    },
    {
      path: 'sales_statistical/report_inventory_by_product',
      children: [{ index: true, element: <ReportInventoryByProduct /> }],
    },
    {
      path: 'sales_statistical/report_inventory_group_product',
      children: [{ index: true, element: <ReportInventoryGroupProduct /> }],
    },
    {
      path: 'sales_statistical/inventorybyperiod',
      children: [{ index: true, element: <ReportInventoryByPeriod /> }],
    },
    {
      path: 'sales/return-merchandise',
      children: [{ index: true, element: <ReturnMerchandise /> }],
    },
    {
      path: 'sales_statistical/daily_expenses',
      children: [{ index: true, element: <ReportDailyExpenses /> }],
    },
    {
      path: 'sales_statistical/profit-and-loss-report',
      children: [{ index: true, element: <ProfitAndLossReport /> }],
    }
  ],
};

export default HKCareRoutes;
