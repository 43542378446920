import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import entryService from 'services/entry.service';
import userService from 'services/user.service';
import { FilterParams } from 'types';

interface IInitialState {}

const initialState: IInitialState = {};

export const getAllEntry = createAsyncThunk(
  'entry/getAll',
  async (filters: FilterParams, { rejectWithValue }) => {
    try {
      const { data } = await entryService.getAll(filters);

      if (data.items) {
        const totalExpendituretotal = data.items[0].totalExpendituretotal;
        const totalRevenue = data.items[0].totalRevenue;
        const entryList = data.items[0].items;
        const totalCount = data.totalCount;

        return {
          entryList,
          totalCount,
          totalExpendituretotal,
          totalRevenue,
        };
      }

      return rejectWithValue('Get data fail');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllEntryType = createAsyncThunk(
  'entry/getAllEntryType',
  async (filters: any, { rejectWithValue }) => {
    try {
      const { data } = await entryService.getEntryType(filters);
      if (data.items) {
        const entryTypeList = data.items;
        const totalCount = data.totalCount;

        return {
          entryTypeList,
          totalCount,
        };
      }

      return rejectWithValue('Get data fail');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAudience = createAsyncThunk(
  'entry/getAudience',
  async (filters: { audienceId: any }, { rejectWithValue }) => {
    try {
      const { data } = await entryService.getAudience(filters);

      if (data) {
        const audienceList = data;
        const totalCount = data.length;

        return {
          audienceList,
          totalCount,
        };
      }

      return rejectWithValue('Get data fail');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getEntryAudience = createAsyncThunk(
  'entry/getEntryAudience',
  async (filters: { audienceType: any }, { rejectWithValue }) => {
    try {
      const { data } = await entryService.getEntryAudience(filters);

      if (data) {
        const audienceList = data;
        const totalCount = data.length;

        return {
          audienceList,
          totalCount,
        };
      }

      return rejectWithValue('Get data fail');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getEntry = createAsyncThunk(
  'entry/get',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await entryService.get(id);

      if (data) {
        const entry = data;

        return {
          entry,
        };
      }
      return rejectWithValue('Get data fail');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUser = createAsyncThunk(
  'entry/getUser',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await userService.get(id);
      if (data) {
        const user = data;

        return {
          user,
        };
      }

      return rejectWithValue('Get data fail');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createEntry = createAsyncThunk(
  'entry/create',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await entryService.create(payload);
      if (res) {
        return {
          res: res.data,
          status: 200,
        };
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateEntry = createAsyncThunk(
  'entry/update',
  async (payload: any, { rejectWithValue }) => {
    try {
      await entryService.update(payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteEntry = createAsyncThunk(
  'entry/delete',
  async (id: number, { rejectWithValue }) => {
    try {
      await entryService.delete(id);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const entrySlice = createSlice({
  name: 'entry',
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export default entrySlice.reducer;
