import { lazy } from "react";
import { Outlet, RouteObject } from "react-router-dom";
import Loadable from "./Loadable";
import { withPermission } from "contexts/RouterWithPermission";
import InventoryByPeriod from "redux/slices/inventoryByPeriod";

const ProductGroup = withPermission(Loadable(lazy(() => import('views/HK_Group/Product/Type/ProductGroup'))), "HkGroup.QuanLySanPham.LoaiSanPham.NhomSanPham");
const TreatmentGroup = withPermission(Loadable(lazy(() => import('views/HK_Group/Product/Type/TreatmentGroup'))), "HkGroup.QuanLySanPham.LoaiSanPham.NhomDieuTri");
const Usage = withPermission(Loadable(lazy(() => import('views/HK_Group/Product/Type/Usage'))), "HkGroup.QuanLySanPham.LoaiSanPham.DangDung");
const Pathological = withPermission(Loadable(lazy(() => import('views/HK_Group/Product/Type/Pathological'))), "HkGroup.QuanLySanPham.LoaiSanPham.LoaiBenhLy");
const Measure = withPermission(Loadable(lazy(() => import('views/HK_Group/Product/Measure'))), "HkGroup.QuanLySanPham.DonViDoLuong");
const Supplier = withPermission(Loadable(lazy(() => import('views/HK_Group/Product/Supplier'))), "HkGroup.QuanLySanPham.NhaCungCap");
const SupplierDetails = withPermission(Loadable(lazy(() => import('views/HK_Group/Product/Supplier/Details'))), "HkGroup.QuanLySanPham.NhaCungCap");
const Tenant = withPermission(Loadable(lazy(() => import("views/HK_Group/Tenant"))), "HkGroup.ThongTinDiemBan");
const Users = withPermission(Loadable(lazy(() => import("views/HK_Group/Operate/Users"))), "HkGroup.QuanTriNguoiDung.DanhSachNguoiDung");
const CreateUsers = withPermission(Loadable(lazy(() => import("views/HK_Group/Operate/Users/Create"))), "HkGroup.QuanTriNguoiDung.DanhSachNguoiDung");
const ProductList = withPermission(Loadable(lazy(() => import('views/HK_Group/Product/ProductList'))), "HkGroup.QuanLySanPham.DanhSachSanPham");
const ProductDetails = withPermission(Loadable(lazy(() => import('views/HK_Group/Product/ProductList/Details'))), "HkGroup.QuanLySanPham.DanhSachSanPham");
const ReferencePrices = withPermission(Loadable(lazy(() => import('views/HK_Group/Product/ReferencePrices'))), "HkGroup.QuanLySanPham.DanhSachSanPham");
// const Roles = Loadable(lazy(() => import('views/HK_Group/Operate/Roles')));
const Roles = withPermission(Loadable(lazy(() => import('views/HK_Care/Operate/RolePermission'))), "HkGroup.QuanTriNguoiDung.PhanQuyen");
const Customers = withPermission(Loadable(lazy(() => import('views/HK_Group/Customer'))), "HkGroup.QuanLyKhachHang");
const CreateSaleCustomers = withPermission(Loadable(lazy(() => import('views/HK_Group/Customer/Create'))), "HkGroup.QuanLyKhachHang");
const ViewSaleCustomers = withPermission(Loadable(lazy(() => import('views/HK_Group/Customer/Detail'))), "HkGroup.QuanLyKhachHang");

const InventoryByHours = withPermission(Loadable(lazy(() => import('views/HK_Care/SalesStatistical/InventoryByHours'))), "HkGroup.BaoCao.BaoCaoTheoGio");
const ReportInventoryByProduct = withPermission(Loadable(lazy(() => import('views/HK_Care/SalesStatistical/ReportInventoryByProduct'))), "HkGroup.BaoCao.BaoCaoTheoSanPham");
const ReportInventoryGroupProduct = withPermission(Loadable(lazy(() => import('views/HK_Care/SalesStatistical/ReportInventoryGroupProduct'))), "HkGroup.BaoCao.BaoCaoTheoNhomSanPham");
const ReportInventoryByPeriod = withPermission(Loadable(lazy(() => import('views/HK_Care/SalesStatistical/InventoryByPeriod'))), "HkGroup.BaoCao.BaoCaoTheoKy");
const RevenueByEmployee = withPermission(Loadable(lazy(() => import('views/HK_Group/Report/RevenueByEmployee'))), "HkGroup.BaoCao.BaoCaoDoanhThuTheoNhanVien");
const InventoryReport = withPermission(Loadable(lazy(() => import('views/HK_Group/Report/RevenueImported'))), "HkGroup.BaoCao.BaoCaoNhapTonKho");
const SearchNearDateReport = withPermission(Loadable(lazy(() => import('views/HK_Care/SalesStatistical/SearchNearDateReport'))), "HkGroup.BaoCao.BaoCaoCanDate");
const DailyExpensesReport = withPermission(Loadable(lazy(() => import('views/HK_Group/Report/DailyExpenses'))), "HkGroup.BaoCao");
const ProfitAndLossReport = withPermission(Loadable(lazy(() => import('views/HK_Care/SalesStatistical/ProfitAndLossReport'))), "HkGroup.BaoCao");

const HKGroupRoutes: RouteObject = {
  path: "hk_group",
  element: <Outlet />,
  children: [
    {
      path: "product/type/product_group",
      children: [{ index: true, element: <ProductGroup /> }],
    },
    {
      path: "product/type/treatment_group",
      children: [{ index: true, element: <TreatmentGroup /> }],
    },
    {
      path: "product/type/usage",
      children: [{ index: true, element: <Usage /> }],
    },
    {
      path: "product/type/pathological",
      children: [{ index: true, element: <Pathological /> }],
    },
    {
      path: "product/measure",
      children: [{ index: true, element: <Measure /> }],
    },
    {
      path: "product/supplier",
      children: [{ index: true, element: <Supplier /> }],
    },
    {
      path: "product/supplier/:id",
      children: [{ index: true, element: <SupplierDetails /> }],
    },
    {
      path: "product/list",
      children: [{ index: true, element: <ProductList /> }],
    },
    {
      path: "product/list/:id",
      children: [{ index: true, element: <ProductDetails /> }],
    },
    {
      path: 'product/reference_prices',
      children: [{ index: true, element: <ReferencePrices /> }]
    },
    {
      path: "tenant",
      children: [{ index: true, element: <Tenant /> }],
    },
    {
      path: "operate/users",
      children: [{ index: true, element: <Users /> }],
    },
    {
      path: "operate/users/create",
      children: [{ index: true, element: <CreateUsers /> }],
    },
    {
      path: "operate/users/update/:id",
      children: [{ index: true, element: <CreateUsers /> }],
    },
    {
      path: "operate/users/view/:id",
      children: [{ index: true, element: <CreateUsers /> }],
    },
    {
      path: 'operate/roles',
      children: [{ index: true, element: <Roles /> }],
    },
    {
      path: 'customer',
      children: [{ index: true, element: <Customers /> }],
    },
    {
      path: 'customer/create',
      children: [{ index: true, element: <CreateSaleCustomers /> }],
    },
    {
      path: 'customer/update/:id',
      children: [{ index: true, element: <ViewSaleCustomers /> }],
    },
    {
      path: 'customer/view/:id',
      children: [{ index: true, element: <ViewSaleCustomers /> }],
    },
    {
      path: 'sales_statistical/sales_report',
      children: [{ index: true, element: <RevenueByEmployee /> }],
    },
    {
      path: 'sales_statistical/search_near_date',
      children: [{ index: true, element: <SearchNearDateReport /> }],
    },
    {
      path: 'sales_statistical/inventory_report',
      children: [{ index: true, element: <InventoryReport /> }],
    },
    {
      path: 'sales_statistical/inventorybyhours',
      children: [{ index: true, element: <InventoryByHours /> }],
    },
    {
      path: 'sales_statistical/report_inventory_by_product',
      children: [{ index: true, element: <ReportInventoryByProduct /> }],
    },
    {
      path: 'sales_statistical/report_inventory_group_product',
      children: [{ index: true, element: <ReportInventoryGroupProduct /> }],
    },
    {
      path: 'sales_statistical/inventorybyperiod',
      children: [{ index: true, element: <ReportInventoryByPeriod /> }],
    },
    {
      path: 'sales_statistical/daily-expenses-report',
      children: [{ index: true, element: <DailyExpensesReport /> }],
    },
    {
      path: 'sales_statistical/profit-and-loss-report',
      children: [{ index: true, element: <ProfitAndLossReport /> }],
    }
  ],
};

export default HKGroupRoutes;
