import PrintIcon from '@mui/icons-material/Print';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { LoadingScreen } from 'components/common';
import { useNotification } from 'hooks';
import { FormValueReturn } from 'interface/HKCare/ReturnMerchandise';
import moment from 'moment';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
  getReturnMerchandiseById,
  getTenantDetail,
} from 'redux/slices/returnMerchandise';
import { numberFormat } from 'utils/numberFormat';

interface Tenant {
  address: string;
  name: string;
  phone: string;
}

const Index = () => {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const setNotification = useNotification();
  const [loading, setLoading] = useState<boolean>(true);
  const componentRef = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<Partial<FormValueReturn>>({});
  const [tenant, setTenant] = useState<Tenant | null>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
  });

  const fetchListData = async () => {
    if (!params.id) return;
    const { payload, error } = await dispatch(
      getReturnMerchandiseById(params.id)
    );
    setLoading(false);
    if (error) {
      setNotification({ error: 'Hệ thống đang xử lý' });
      return;
    }
    setData(payload || {});
  };

  const fetchTenant = async () => {
    const { payload, error } = await dispatch(getTenantDetail(''));
    if (error) {
      setNotification({ error: 'Hệ thống đang xử lý' });
      return;
    }
    setTenant(payload.data);
  };

  const total = useMemo(() => {
    const totalValue =
      data.returnDetails?.reduce(
        (acc, cur) => acc + (cur?.returnAmount || 0) * (cur?.orderPrice || 0),
        0
      ) || 0;
    return totalValue;
  }, [data]);
  const discountByPrd = useMemo(() => {
    const totalValue =
      data.returnDetails?.reduce(
        (acc, cur) =>
          acc +
          ((cur.discount || 0) / (cur.orderAmount || 0)) *
            (cur.returnAmount || 0),
        0
      ) || 0;
    return totalValue;
  }, [data]);

  useEffect(() => {
    fetchListData();
    fetchTenant();
  }, [params.id]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>In hoá đơn trả hàng</title>
      </Helmet>

      <Box>
        <IconButton
          sx={{
            position: 'absolute',
            top: 20,
            right: 20,
            background: 'white',
          }}
          onClick={handlePrint}
        >
          <PrintIcon />
        </IconButton>
      </Box>
      <Box ref={componentRef}>
        <Stack
          sx={{
            minHeight: '100vh',
            background: '#137b3e',
            position: 'relative',
            fontSize: '12px',
          }}
          justifyContent="space-around"
          flexDirection="row"
          className="print"
        >
          <Box
            sx={{
              width: '8cm',
              background: 'white',
              right: '50%',
              minHeight: '100vh',
              color: 'black',
            }}
            ref={componentRef}
          >
            <Box sx={{ padding: '30px 10px' }}>
              <Stack
                flexDirection="row"
                // alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 2 }}
              >
                <Box>
                  <Box
                    component="img"
                    sx={{
                      width: '3cm',
                    }}
                    src="/static/logo.png"
                  />
                  <h5 style={{ margin: 0 }}>HỆ THỐNG HK CARE</h5>
                </Box>
                <Box sx={{ textAlign: 'right', fontSize: '12px' }}>
                  <Box sx={{ whiteSpace: 'no-wrap' }}>{tenant?.name}</Box>
                  <Box>Địa chỉ: {tenant?.address}</Box>
                  <Box>Điện thoại: {tenant?.phone}</Box>
                </Box>
              </Stack>

              <Box sx={{ textAlign: 'center', mb: 2 }}>
                <Typography variant="h6" style={{ fontSize: '13px' }}>
                  HÓA ĐƠN TRẢ HÀNG
                </Typography>
                (Ngày {moment(new Date()).format('DD/MM/YYYY')})
              </Box>
              <Stack flexDirection="row" mb={2} justifyContent="space-between">
                <Box>
                  Dược sĩ: <b>{data?.creatorName}</b>
                </Box>
                <Box>
                  Mã HĐTH: <b>{data?.code}</b>
                </Box>
              </Stack>
              <table
                style={{
                  marginBottom: '20px',
                  width: '100%',
                  fontSize: '11px',
                }}
              >
                <thead>
                  <tr>
                    <td colSpan={6} style={{ padding: '0' }}>
                      <Divider style={{ borderTop: '1px solid black' }} />
                    </td>
                  </tr>
                  <tr>
                    <td>Sản phẩm</td>
                    <td style={{ padding: '3px', textAlign: 'center' }}>
                      Đ.Vị
                    </td>
                    <td style={{ padding: '3px', textAlign: 'right' }}>
                      SLTH
                    </td>
                    <td style={{ padding: '3px', textAlign: 'right' }}>
                      Đ.Giá
                    </td>
                    <td style={{ textAlign: 'right' }}>T.Tiền</td>
                  </tr>
                  <tr>
                    <td colSpan={6} style={{ padding: '0' }}>
                      <Divider style={{ borderTop: '1px solid black' }} />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {data?.returnDetails?.map((item) => {
                    return (
                      <tr key={item.returnDetailId}>
                        <td style={{ width: '3cm' }}>{item.productName}</td>
                        <td style={{ width: '1cm', textAlign: 'center' }}>
                          {item.unit}
                        </td>
                        <td style={{ width: '1cm', textAlign: 'right' }}>
                          {numberFormat(item.returnAmount || 0)}
                        </td>
                        <td style={{ width: '1.5cm', textAlign: 'right' }}>
                          {numberFormat(item.orderPrice || 0)}
                        </td>
                        <td style={{ width: '1.5cm', textAlign: 'right' }}>
                          {numberFormat(
                            (item.returnAmount || 0) * (item.orderPrice || 0)
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Divider style={{ borderTop: '1px dashed black' }} />
              <div
                style={{ width: '6cm', marginLeft: 'auto', padding: '10px' }}
              >
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  <Box>Tổng tiền:</Box>
                  <Box>{numberFormat(total)}</Box>
                </Stack>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  <Box>Chiết khấu sản phẩm:</Box>
                  <Box>{numberFormat(discountByPrd)}</Box>
                </Stack>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  <Box>Chiết khấu hoá đơn:</Box>
                  <Box>{numberFormat(data?.discountValue || 0)}</Box>
                </Stack>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  <Box>
                    <Typography sx={{ fontWeight: 'medium', fontSize: '12px' }}>
                      Tiền mặt trả khách:
                    </Typography>
                  </Box>
                  <Box>{numberFormat(data?.totalReturn || 0)}</Box>
                </Stack>
              </div>
              <Divider style={{ borderTop: '1px dashed black' }} />

              <Box sx={{ mt: 1 }}>Cảm ơn quý khách.</Box>
              <Box>Mọi thắc mắc quý khách liên hệ tổng đài:</Box>
              <b>0337 664 222</b>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Fragment>
  );
};

export default Index;
