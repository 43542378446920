import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface Props extends BoxProps {
  title: string;
  description?: string;
  hidden?: boolean;
  hideDivider?: boolean;
  mb?: number;
}

const FormHeader = ({
  title,
  description,
  children,
  hidden,
  hideDivider,
  mb = 2,
  ...rest
}: Props) => {
  return hidden ? (
    <></>
  ) : (
    <Box sx={{ mb }} {...rest}>
      <Typography
        color="text.secondary"
        sx={{ mb: 1.5, fontWeight: 'regular', fontSize: '1.25rem' }}
      >
        {title}
      </Typography>
      {!hideDivider && <Divider />}
      {children ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mb: -1 }}
        >
          <Stack direction="row" spacing={1}>
            {children}
          </Stack>
        </Box>
      ) : null}
      {description && (
        <Typography
          variant="h6"
          color="text.secondary"
          sx={{ fontWeight: 'light', mt: 1 }}
        >
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default FormHeader;
