import { Fragment } from 'react';
import ConfirmDialog from './component/Confirm';
import CashClosingDialog from './component/IncomeAndExpenses/IncomeAndExpenses';
import ShiftHandoverDialog from './component/ShiftHandover/ShiftHandoverDialog';
import ShiftHandoverConfirmation from './component/ShiftHandoverConfirmation/ShiftHandoverConfirmation';

const Index = () => {
  return (
    <Fragment>
      <CashClosingDialog />
      <ConfirmDialog />
      <ShiftHandoverDialog />
      <ShiftHandoverConfirmation />
    </Fragment>
  );
};

export default Index;
