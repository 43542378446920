import axiosClient from 'api';
import { baseURL } from 'config';
// import { IMeasure } from 'interface';
import { IRolePermission } from 'interface';
import { FilterParams } from 'types';

class RoleService {

  // getAllRole() {
  //   return axiosClient.get(
  //     `${baseURL}/GrantPermission/GetAllRole`
  //   );
  // }

  getAllRole({ pageIndex, pageSize }: FilterParams) {
    return axiosClient.get(`${baseURL}/GrantPermission/GetAllRole`, {
      params: {
        pageIndex,
        pageSize,
      },
    });
  }

  getAllPermission() {
    return axiosClient.get(
      `${baseURL}/GrantPermission/GetPermission`
    );
  }

  create(payload: IRolePermission) {
    return axiosClient.post(`${baseURL}/GrantPermission/CreateRole`, payload);
  }

  update({ id, ...payload }: IRolePermission) {
    return axiosClient.put(`${baseURL}/GrantPermission/UpdateRole`, payload,
      {
        params: { id },
      }
    );
  }

  delete(id: string) {
    return axiosClient.delete(`${baseURL}/GrantPermission/DeleteRole`, {
      params: { id },
    });
  }


  detail(id: string | null) {
    return axiosClient.get(`${baseURL}/GrantPermission/ReadRole`, {
      params: { id },
    });
  }

  getRolePermissionCurrent() {
    return axiosClient.get(`${baseURL}/GrantPermission/GetCurrentUserPermission`);
  }
}

export default new RoleService();
