import { InputAdornment, Stack } from '@mui/material';
import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { memo, useState } from 'react';
import { useDebounce } from 'react-use';
import type { KeyDownEvent } from 'types';
import SearchIcon from '@mui/icons-material/Search';
interface Props extends BoxProps {
  title?: string;
  placeHolder: string;
  onSearch: (searchTerm: string) => void;
  searchText: string;
  haveIcon?: boolean;
  typeSearchIcon?: boolean;
  setTypeEnter?: any;
  
}

const SearchField = (props: Props) => {
  const {
    title,
    searchText,
    placeHolder,
    onSearch,
    children,
    haveIcon,
    onFocus,
    onBlur,
    onClick,
    typeSearchIcon,
    setTypeEnter,
    ...rest
  } = props;
  const [value, setValue] = useState<string>('');

  const handleKeyDown: KeyDownEvent = async (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSearch(value);
      setTypeEnter(true)
      setValue('')
    }
  };
  

  useDebounce(
    () => {
      if (searchText !== value) {
        onSearch(value);
      }
    },
    1500,
    [value]
  );

  if (haveIcon) {
    return (
      <Stack
        flexDirection="row"
        style={{ position: 'relative', width: '100%' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            justifyContent: 'space-between',
          }}
        >
          {title ? (
            <FormLabel htmlFor="search">
              <Typography gutterBottom sx={{ mb: 0, fontSize: '1.74rem' }}>
                {title}
              </Typography>
            </FormLabel>
          ) : null}
          {children}
        </Box>
        <TextField
          id="search"
          fullWidth
          placeholder={placeHolder}
          variant="outlined"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onClick={onClick}
          autoComplete='off'
          onKeyDown={handleKeyDown}
          sx={{
            mr: 1,
            display: 'grid',
            flexGrow: 1,
            backgroundColor: 'white',
            borderRadius: '4px',
          }}
          onFocusCapture={onFocus}
          onBlurCapture={onBlur}

        // {...rest}
        />
        {/* <SearchIcon
          fontSize="medium"
          style={{ position: 'absolute', right: '20', top: '23%' }}
          color="disabled"
        /> */}
      </Stack>
    );
  }

  return (
    <>
      {title && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            justifyContent: 'space-between',
          }}
        >
          {title ? (
            <FormLabel htmlFor="search">
              <Typography gutterBottom sx={{ mb: 0, fontSize: '1.25rem' }}>
                {title}
              </Typography>
            </FormLabel>
          ) : null}
          {children}
        </Box>
      )}

      <TextField
        id="search"
        fullWidth
        placeholder={placeHolder}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        InputProps={{
          endAdornment: typeSearchIcon && (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onKeyDown={handleKeyDown}
        
        sx={{
          mr: 1,
          flexGrow: 1,
          backgroundColor: 'white',
          borderRadius: '4px',
        }}
      />
    </>
  );
};

export default memo(SearchField);
