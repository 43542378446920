import { useState } from 'react';

interface FiltersParams {
  pageIndex: number;
  pageSize: number;
}

const useFilters = () => {
  const [filters, setFilters] = useState<FiltersParams>({
    pageIndex: 1,
    pageSize: 10,
  });

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setFilters((state) => ({
      ...state,
      pageIndex: 1,
      pageSize: rowsPerPage,
    }));
  };

  const handleChangePage = (pageIndex: number) => {
    setFilters((state) => ({
      ...state,
      pageIndex,
    }));
  };

  return {
    filters,
    handleChangeRowsPerPage,
    handleChangePage,
  };
};

export default useFilters;
