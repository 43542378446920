import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

export interface IOptions {
  label: string;
  value: string;
}

interface IProps {
  listData: IOptions[];
  loading: boolean;
  placeholder: string;
  // handleSubmit: (options: IOptions[]) => void;
  noDataText: string;
  disabled?: boolean;
  value: IOptions[];
  setValue: (value: IOptions[]) => void;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CheckboxesTags = ({
  listData,
  loading,
  placeholder,
  // handleSubmit,
  noDataText,
  disabled,
  value,
  setValue,
}: IProps) => {
  const [open, setOpen] = useState(false);

  const openPopper = () => setOpen(true);
  const closePopper = () => setOpen(false);

  return (
    <Autocomplete
      multiple
      options={listData.length > 1 ? listData : []}
      open={open}
      onOpen={openPopper}
      onClose={closePopper}
      disableCloseOnSelect
      disableClearable
      disabled={disabled}
      getOptionLabel={(option) => option.label}
      noOptionsText={noDataText}
      loading={loading}
      value={value}
      renderOption={(props, option, { selected }) => {
        let isSelectedAll = value.find((item: any) => option.value === 'all');
        if (isSelectedAll) {
          return (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={true}
                indeterminate={
                  value.length === listData.length || value.length === 0
                    ? false
                    : true
                }
              />
              {option.label}
            </li>
          );
        }
        return (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        );
      }}
      onChange={(e, v) => {
        const checkAll = (v.filter((x) => x.value === 'all') || []).length;
        const oldCheckAll = (value.filter((x) => x.value === 'all') || [])
          .length;
        if (checkAll === oldCheckAll) {
          if (v.length + 1 === listData.length && checkAll === 0) {
            setValue(listData);
            return;
          }
          if (v.length + 1 === listData.length && checkAll === 1) {
            setValue(v.filter((x) => x.value !== 'all'));
            return;
          }
          setValue(v);
        } else {
          if (checkAll === 1) {
            setValue(listData);
          } else {
            setValue([]);
          }
        }
      }}
      renderInput={(params) => {
        params.InputProps.startAdornment = undefined;
        return (
          <TextField
            {...params}
            sx={{ minWidth: '250px' }}
            placeholder={placeholder}
            label=""
          />
        );
      }}
    />
  );
};

export default CheckboxesTags;
