import axiosClient from 'api';
import { baseURL } from 'config';

// interface typeFilters {
//   [key: string]: any;
// }

class PrintBarcodeService {
  getBarcodeToPrint(payload: any[]) {
    return axiosClient.post(`${baseURL}/product/PrintTemInfo`,payload);
  }
}
export default new PrintBarcodeService();
