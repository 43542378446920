import axiosClient from 'api';
import { baseURL } from 'config';

interface typeFilters {
  [key: string]: any;
}

class CashClosingService {
  getClosingWorkingAsnyc({ pageIndex, pageSize }: typeFilters) {
    return axiosClient.get(`${baseURL}/Finance/ClosingWorkingAsnyc`, {
      params: {
        PageIndex: pageIndex,
        PageSize: pageSize,
        Offset: pageSize,
      },
    });
  }
  checkCashClosing() {
    return axiosClient.post(`${baseURL}/ClosingWork/ClosingWorkingAsnyc`);
  }
  createUpdateClosingWork(payload: typeFilters) {
    return axiosClient.post(`${baseURL}/Finance/CreateClosingWork`, {
      ...payload,
    });
  }
  createFinanceCheckClosingWork(payload: typeFilters) {
    const { id } = payload;
    return axiosClient.post(
      `${baseURL}/Finance/CreateUpdateCheckClosingWork?Id=${id}`,
      {
        ...payload,
      }
    );
  }

  checkFinanceByClosingWorkAsnyc(totalMoney: number) {
    return axiosClient.post(
      `${baseURL}/Finance/CheckFinanceByClosingWorkAsnyc?ToTalMoney=${totalMoney}`
    );
  }
}
export default new CashClosingService();
