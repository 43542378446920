import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import {
  FormFooter,
  FormHeader,
  FormLabel,
  FormPaperGrid,
} from 'components/Form';
import ControllerNumberInput from 'components/Form/ControllerNumberInput';
import { useNotification } from 'hooks';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkUpdateMessage,
  createFinanceCheckClosingWork,
  handleCloseShiftHandoverConfirmation,
} from 'redux/slices/cashClosing';
import { getEntry } from 'redux/slices/entry';
import { RootState } from 'redux/store';
import { numberFormat } from 'utils/numberFormat';
import * as yup from 'yup';

interface FormValue {
  audienceType: number | null;
  audienceId: string | null;
  typeFinance: number | null;
  idTypeFinance: number | null;
  creatTime: Date | string | null;
  money: number;
  note: string;
  nameCreator: string;
  creatorId: string;
  idAudienceType: number;
}

const validationSchema = yup.object().shape({
  audienceId: yup
    .string()
    .nullable()
    .required('Vui lòng chọn đối tượng.')
    .default(null),
  typeFinance: yup.number().nullable().default(1),
  idTypeFinance: yup.number().default(0),
  creatTime: yup.date().nullable().default(null),
  money: yup.number().required('Vui lòng nhập số tiền').default(0),
  note: yup.string().default(''),
  nameCreator: yup.string().default(''),
  creatorId: yup.string().default(''),
  idAudienceType: yup.number().default(0),
});

const ShiftHandoverConfirmation = () => {
  const dispatch = useDispatch<any>();
  const { openShiftHandoverConfirmation, idUpdate, idMessage, isUpdateMessage } = useSelector(
    (state: RootState) => state.cashClosing
  );

  const [totalMoney, setTotalMoney] = useState<number>(0);
  const setNotification = useNotification();

  const { control, setValue, handleSubmit, reset } = useForm<FormValue>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: validationSchema.getDefault(),
  });

  const money = useWatch({
    control,
    name: 'money',
  });
  const createdTime = useWatch({
    control,
    name: 'creatTime',
  });
  const nameCreator = useWatch({
    control,
    name: 'nameCreator',
  });

  const fetchDataUpdate = async () => {
    const { payload, error } = await dispatch(getEntry(idUpdate));

    if (error) {
      setNotification({
        error: 'Hệ thống đang xử lý.',
      });
      return;
    }
    reset(payload.entry);
    setTotalMoney(payload?.entry?.money || 0);
  };

  useEffect(() => {
    if (!idUpdate || !openShiftHandoverConfirmation) return;
    fetchDataUpdate();
  }, [openShiftHandoverConfirmation, idUpdate]);

  const handleClose = () => {
    reset(validationSchema.getDefault());
    dispatch(handleCloseShiftHandoverConfirmation());
  };

  const onSubmit = async (value: FormValue) => {
    const date = new Date();
    const params: Record<string, any> = {
      audienceType: value.idAudienceType,
      audienceId: value.creatorId,
      typeFinance: 0,
      idTypeFinance: 8,
      creatTime: moment(date).format('YYYY-MM-DDTHH:mm:ss'),
      money: value.money,
      note: '',
      id: idMessage,
    };
    const { error } = await dispatch(createFinanceCheckClosingWork(params));
    if (error) {
      setNotification({ error: 'Hệ thống đang xử lý.' });
      return;
    }
    setNotification({ message: 'Thành công.', severity: 'success' });
    handleClose();
    dispatch(checkUpdateMessage());
  };

  return (
    <Dialog
      open={openShiftHandoverConfirmation}
      // open={true}
      maxWidth={'sm'}
      fullWidth
      onClose={handleClose}
    >
      <FormPaperGrid onSubmit={handleSubmit(onSubmit)}>
        <FormHeader sx={{ textAlign: 'center' }} title="XÁC NHẬN BÀN GIAO CA" />
        <Stack gap={0.5}>
          <Typography variant="body1" gutterBottom>
            Nhân viên
            <TextFigure> {nameCreator} </TextFigure>bàn giao
            <TextFigure> {numberFormat(totalMoney)} </TextFigure>
            cho bạn lúc
            <TextFigure> {moment(createdTime).format('HH:mm')} </TextFigure>
            ngày
            <TextFigure> {moment(createdTime).format('DD/MM/YYYY')}</TextFigure>
            .
          </Typography>
          <Typography gutterBottom>Xác nhận đã nhận tiền bàn giao?</Typography>
          <Box>
            <FormLabel title="Số tiền thực nhận" required name="money" />
            <ControllerNumberInput
              name="money"
              setValue={setValue}
              value={money}
              control={control}
            />
          </Box>
        </Stack>
        <FormFooter>
          <Button variant="outlined" onClick={handleClose}>
            Quay lại
          </Button>
          <LoadingButton type="submit">Xác nhận</LoadingButton>
        </FormFooter>
      </FormPaperGrid>
    </Dialog>
  );
};

const TextFigure = styled('span')({
  fontWeight: 500,
});

export default ShiftHandoverConfirmation;
