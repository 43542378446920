
import axiosClient from 'api';
import { IProfile, IPassword, IUpdatePassword, ISendOTP, IOTP } from 'interface';
import { baseURL } from 'config';

class ProfileService {
  getProfile() {
    return axiosClient.get(`${baseURL}/SalePointEmployee/ViewMyProfile`);
  }

  update({ id, ...payload }: IProfile) {
    return axiosClient.put(`${baseURL}/SalePointEmployee/UpdateMyProfile`,
      payload,
      {
        params: { id },
      }
    )
  };

  changePassword({ id, ...payload }: IPassword) {
    return axiosClient.put(`${baseURL}/SalePointEmployee/UpdatePasswordMyProfile`, payload, {
      params: { id }
    });
  }

  sendOtp(payload: ISendOTP) {
    return axiosClient.get(`${baseURL}/otp/SendOTP`, {
      params: {
        toEmail: payload.email,
      }
    });
  }

  checkOtp(payload: IOTP) {
    return axiosClient.post(`${baseURL}/otp/CheckVaildOTP?Otp=${payload.otp}`);
  }

  updatePasswordWithOtp({ id, ...payload }: IUpdatePassword) {
    return axiosClient.put(`${baseURL}/otp/UpdatePasswordWithOTP`, payload, {
      params: { id }
    }
    );
  }
}

export default new ProfileService();
