import { lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import Loadable from './Loadable';

const UserProfile = Loadable(lazy(() => import('views/User/UserProfile')));
const Profile = Loadable(lazy(() => import('views/User/Profile')));

const UserRoutes: RouteObject = {
  path: 'user',
  element: <Outlet />,
  children: [
    // {
    //   path: 'profile',
    //   children: [{ index: true, element: <UserProfile /> }],
    // },
    {
      path: 'profile',
      children: [{ index: true, element: <Profile /> }],
    },
  ],
};

export default UserRoutes;
