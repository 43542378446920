import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  Typography,
} from '@mui/material';
import useNotificationMessage from 'hooks/useNotificationMessage';
import { useEffect, useRef, useState } from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import 'moment/locale/vi';
import notificationService from 'services/notification.service';
import { defaultFilters } from 'constants/defaultFilters';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handleOpenShiftHandoverConfirmation } from 'redux/slices/cashClosing';

const GridStyled = styled(Grid)(({ theme }) => ({
  cursor: 'pointer',
  ':hover': {
    backgroundColor: '#99e59e',
  },
}));

interface ITotal {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
}

const PopperMessage = ({ anchorEl, handleClose }: ITotal) => {
  const moment = require('moment-timezone');
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [stateReadMessage, setStateReadMessage] = useState<string>('All');
  const [checkClick, setCheckClick] = useState<boolean>(false);
  const [filterParamsNew, setFilterParamsNew] = useState<any>({
    ...defaultFilters,
  });
  const now = new Date();

  const {
    total,
    message,
    messageNotSeen,
    messageSeen,
    setFilterParams,
    filterParams,
    setTotal,
  } = useNotificationMessage();
  
  const functionRenderMessage = () => {
    return (
      <>
        {stateReadMessage === 'All' ? (
          <>
            {message.map((mess: any, index: any) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: !mess.isRead ? '#f4f5f7' : 'white',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <GridStyled
                  container
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignContent={'center'}
                  alignItems={'center'}
                  onClick={() => (
                    navigateFunction(mess),
                    UpdateAsReadWithId(mess.id)
                  )}
                >
                  <Grid sx={{ p: 3 }} xs={11} md={11} item>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: 700,
                        color: '#484848',
                      }}
                    >
                      {mess.title}
                    </Typography>
                    <Typography style={{ color: '#484848', fontSize: 14 }}>
                      {mess.content}
                    </Typography>
                    {index === 0 ? (
                      <>
                        <Typography style={{ color: '#409d47', fontSize: 14 }}>
                          Bây giờ
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography style={{ color: '#409d47', fontSize: 14 }}>
                          {moment
                            .tz(new Date(mess?.createTime), 'Asia/Vientiane')
                            .locale('vi')
                            .startOf('minute')
                            .fromNow()}
                        </Typography>
                      </>
                    )}
                  </Grid>
                  {mess.isRead ? null : (
                    <Grid sx={{ p: '0 0 0 10px' }} xs={1} md={1} item>
                      <FiberManualRecordIcon
                        style={{ width: 15, color: '#07c431' }}
                      />
                    </Grid>
                  )}
                </GridStyled>
              </Box>
            ))}
          </>
        ) : stateReadMessage === 'Read' ? (
          <>
            {messageSeen.map((mess: any, index: any) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: mess.isRead ? '#f4f5f7' : 'white',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <GridStyled
                  container
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignContent={'center'}
                  alignItems={'center'}
                  onClick={() => (
                    navigateFunction(mess),
                    UpdateAsReadWithId(mess.id)
                  )}
                >
                  <Grid sx={{ p: 3 }} xs={11} md={11} item>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: 700,
                        color: '#484848',
                      }}
                    >
                      {mess.title}
                    </Typography>
                    <Typography style={{ color: '#484848', fontSize: 14 }}>
                      {mess.content}
                    </Typography>
                  </Grid>
                </GridStyled>
              </Box>
            ))}
          </>
        ) : (
          <>
            {messageNotSeen.map((mess: any, index: any) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: '#f4f5f7',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <GridStyled
                  container
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignContent={'center'}
                  alignItems={'center'}
                  onClick={() => (
                    navigateFunction(mess),
                    UpdateAsReadWithId(mess.id)
                  )}
                >
                  <Grid sx={{ p: 3 }} xs={11} md={11} item>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: 700,
                        color: '#484848',
                      }}
                    >
                      {mess.title}
                    </Typography>
                    <Typography style={{ color: '#484848', fontSize: 14 }}>
                      {mess.content}
                    </Typography>
                    {index === 0 ? (
                      <>
                        <Typography style={{ color: '#409d47', fontSize: 14 }}>
                          Bây giờ
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography style={{ color: '#409d47', fontSize: 14 }}>
                          {moment
                            .tz(new Date(mess?.createTime), 'Asia/Vientiane')
                            .locale('vi')
                            .startOf('minute')
                            .fromNow()}
                        </Typography>
                      </>
                    )}
                  </Grid>
                  {mess.isRead ? null : (
                    <Grid sx={{ p: '0 0 0 10px' }} xs={1} md={1} item>
                      <FiberManualRecordIcon
                        style={{ width: 15, color: '#07c431' }}
                      />
                    </Grid>
                  )}
                </GridStyled>
              </Box>
            ))}
          </>
        )}

      </>

    );
  };
  const navigateFunction = (message: any) => {
    console.log("message::", message);
    const {notificationType, hyperLinkProductId, hyperLinkId, id, isCheck} = message;
    switch (notificationType) {
      case 0:
        window.open(`/hk_care/product/list/${hyperLinkProductId}?typeTab=priceChangeHistory`, '_blank')
        break;
      case 1:
        window.open(`/hk_care/product/list/create?idProduct=${hyperLinkProductId}`, '_blank')
        break;

      case 2:
        window.open(`/hk_care/warehouse/import/receipt/detail-req-hkTrading/${hyperLinkId}`, '_blank')
        break;
      case 3:
        window.open(`/hk_trading/import-management/import-requests-form/${hyperLinkId}`, '_blank')
        break;
      case 4:
        window.open(`/hk_care/notifyview?hyperLinkProductId=${hyperLinkId}`, '_blank')
        break;
      case 5:
        if(isCheck) {
        window.open(`/hk_care/entry/view/${hyperLinkProductId}`, '_blank')
        } else {
          dispatch(handleOpenShiftHandoverConfirmation({idUpdate: hyperLinkProductId, idMessage: id}));
        }
        break;
      default:
        break;
    }
    handleClose()
  }

  useEffect(() => {
    functionRenderMessage();
  }, [stateReadMessage]);

  const functionAllReaded = async () => {
    try {
      const response: any = await notificationService.UpdateAsReadAll();
      setTotal({
        all: 0,
        notSeen: 0,
        seen: 0,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const UpdateAsReadWithId = async (id: any) => {
    try {
      const response: any = await notificationService.UpdateAsReadWithId(id);
      setTotal({
        all: 0,
        notSeen: 0,
        seen: 0,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Popover
      onScrollCapture={(e: any) => {
        if ((e.target.scrollTop + e.target.clientHeight) / e.target.scrollHeight == 1) {
          setFilterParams({ ...filterParams, pageSize: (Number(filterParams.pageSize) + 10) })
        }
      }}
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}

      PaperProps={{
        style: {
          borderRadius: '5px',
        },
      }}
    >
      <div style={{ width: 600, maxHeight: 900 }}>
        <Grid
          display={'flex'}
          flexDirection={'row'}
          p={'20px 0 20px 20px'}
          gap={2}
        >
          <Typography
            style={{ color: '#484848', fontSize: 24, fontWeight: 700 }}
          >
            Thông báo
          </Typography>
          <Button
            style={!checkClick ? {
              borderRadius: 30,
              backgroundColor: '#409d47',
              fontWeight: 700,
            } : {
              borderRadius: 30,
              backgroundColor: '#f4f5f7',
              fontWeight: 700,
              color: '#484848',
              border: '1px solid #e0e0e0',
            }}
            onClick={() => { setStateReadMessage('All'); setCheckClick(false) }}
          >
            Tất cả
          </Button>
          <Button
            style={!checkClick ? {
              borderRadius: 30,
              backgroundColor: '#f4f5f7',
              fontWeight: 700,
              color: '#484848',
              border: '1px solid #e0e0e0',
            } : {
              borderRadius: 30,
              backgroundColor: '#409d47',
              fontWeight: 700,
            }}
            onClick={() => { setCheckClick(true); setStateReadMessage('') }}
          >
            Chưa đọc
          </Button>
          <IconButton onClick={() => functionAllReaded()}>
            <DoneAllIcon /> <Typography>Đánh dấu tất cả đã đọc</Typography>
          </IconButton>
        </Grid>
        {functionRenderMessage()}
      </div>
    </Popover>
  );
};
export default PopperMessage;
