import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { ReactNode, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'redux/store';
import type { Role } from 'types/common';
import LocalStorage from 'utils/LocalStorage';
import SidebarItem from './SidebarItem';
import roleService from 'services/role.service';

interface SectionItem {
  title: string;
  children?: SectionItem[];
  info?: () => JSX.Element | null;
  icon?: ReactNode;
  path?: string;
  roles?: Role[];
}

const getSections = (): SectionItem[] => [
  {
    title: 'HK_Group',
    roles: ['HkGroup.QuanLySanPham', 'HkGroup.ThongTinDiemBan', 'HkGroup.QuanTriNguoiDung', 'HkGroup.QuanLyKhachHang', 'HkGroup.HKTrading', 'HkGroup.QuanLyKho', 'HkGroup'],
    children: [
      {
        title: 'Quản lý sản phẩm',
        path: '/404',
        roles: ['HkGroup.QuanLySanPham'],
        children: [
          {
            title: 'Danh sách sản phẩm',
            path: '/hk_group/product/list',
            roles: ['HkGroup.QuanLySanPham.DanhSachSanPham'],
          },
          {
            title: 'Loại sản phẩm',
            path: '/404',
            roles: ['HkGroup.QuanLySanPham.LoaiSanPham'],
            children: [
              {
                title: 'Nhóm sản phẩm',
                path: '/hk_group/product/type/product_group',
                roles: ['HkGroup.QuanLySanPham.LoaiSanPham.NhomSanPham'],
              },
              {
                title: 'Nhóm điều trị',
                path: '/hk_group/product/type/treatment_group',
                roles: ['HkGroup.QuanLySanPham.LoaiSanPham.NhomDieuTri'],
              },
              {
                title: 'Loại bệnh lý',
                path: '/hk_group/product/type/pathological',
                roles: ['HkGroup.QuanLySanPham.LoaiSanPham.LoaiBenhLy'],
              },
              {
                title: 'Dạng dùng',
                path: '/hk_group/product/type/usage',
                roles: ['HkGroup.QuanLySanPham.LoaiSanPham.DangDung'],
              },
            ],
          },

          {
            title: 'Đơn vị đo lường',
            path: '/hk_group/product/measure',
            roles: ['HkGroup.QuanLySanPham.DonViDoLuong'],
          },
          {
            title: 'Nhà cung cấp',
            path: '/hk_group/product/supplier',
            roles: ['HkGroup.QuanLySanPham.NhaCungCap'],
          },
        ],
      },
      {
        title: 'Thông tin điểm bán',
        path: '/hk_group/tenant',
        roles: ['HkGroup.ThongTinDiemBan'],
      },
      {
        title: 'Quản trị người dùng',
        path: '/404',
        roles: ['HkGroup.QuanTriNguoiDung'],
        children: [
          {
            title: 'Danh sách người dùng',
            path: '/hk_group/operate/users',
            roles: ['HkGroup.QuanTriNguoiDung.DanhSachNguoiDung'],
          },
          {
            title: 'Phân quyền',
            path: '/hk_group/operate/roles',
            roles: ['HkGroup.QuanTriNguoiDung.PhanQuyen'],
          },
        ],
      },
      {
        title: 'Quản lý khách hàng',
        path: '/hk_group/customer',
        roles: ['HkGroup.QuanLyKhachHang'],
      },
      {
        title: 'Báo cáo',
        path: '/404',
        roles: ['HkGroup.BaoCao'],
        children: [
          {
            title: 'Báo cáo doanh thu',
            path: '/hk_group/sales_statistical/sales_report',
            roles: ['HkGroup.BaoCao.BaoCaoDoanhThuTheoNhanVien'],
          },
          {
            title: 'Báo cáo nhập tồn kho',
            path: '/hk_group/sales_statistical/inventory_report',
            roles: ['HkGroup.BaoCao.BaoCaoNhapTonKho'],
          },
          {
            title: 'Báo cáo hàng cận date',
            path: '/hk_group/sales_statistical/search_near_date',
            roles: ['HkGroup.BaoCao.BaoCaoCanDate'],
          },
          {
            title: 'Báo cáo doanh thu theo giờ',
            path: '/hk_group/sales_statistical/inventorybyhours',
            roles: ['HkGroup.BaoCao.BaoCaoTheoGio'],
          },
          {
            title: 'Báo cáo doanh thu theo sản phẩm',
            path: '/hk_group/sales_statistical/report_inventory_by_product',
            roles: ['HkGroup.BaoCao.BaoCaoTheoSanPham'],
          },
          {
            title: 'Báo cáo doanh thu theo nhóm sản phẩm',
            path: '/hk_group/sales_statistical/report_inventory_group_product',
            roles: ['HkGroup.BaoCao.BaoCaoTheoNhomSanPham'],
          },
          {
            title: 'Báo cáo doanh thu theo kỳ',
            path: '/hk_group/sales_statistical/inventorybyperiod',
            roles: ['HkGroup.BaoCao.BaoCaoTheoKy'],
          },
          {
            title: 'Báo cáo thu chi điểm bán',
            path: '/hk_group/sales_statistical/daily-expenses-report',
            roles: ['HkGroup.BaoCao'], // chưa có quyền
          },
          {
            title: 'Báo cáo lãi lỗ',
            path: '/hk_group/sales_statistical/profit-and-loss-report',
            roles: ['HkGroup.BaoCao'], // chưa có quyền
          },
        ],
      },
      {
        title: 'HK_Trading',
        path: '/404',
        roles: ['HkGroup.HKTrading'],
        children: [
          {
            title: 'Quản lý nhập hàng',
            path: '/404',
            roles: ['HkGroup.HKTrading.QuanLyNhapHang'],
            children: [
              {
                title: 'Đơn yêu cầu nhập',
                path: '/hk_trading/import-management/import-requests-form',
                roles: ['HkGroup.HKTrading.QuanLyNhapHang.DonYeuCauNhap'],
              },
              {
                title: 'Tổng hợp yêu cầu nhập',
                path: '/hk_trading/import-management/synthesize-requests',
                roles: ['HkGroup.HKTrading.QuanLyNhapHang.TongHopDonYeuCauNhap'],
              },
              {
                title: 'Kế hoạch nhập tổng hợp',
                path: '/hk_trading/import-management/general-import-plan',
                roles: ['HkGroup.HKTrading.QuanLyNhapHang.KeHoachNhapTongHop'],
              },
              {
                title: 'Kế hoạch nhập chi tiết',
                path: '/hk_trading/import-management/sup-synthesis-plan-detail',
                roles: ['HkGroup.HKTrading.QuanLyNhapHang.KeHoachNhapChiTiet'],
              },
            ],
          },
          {
            title: 'Quản lý kho',
            path: '/404',
            roles: ['HkGroup.QuanLyKho'],
            children: [
              {
                title: 'Kho hàng',
                path: '/hk_trading/management-warehouse/warehouse',
                roles: ['HkGroup.QuanLyKho.QuanLyKho'],
              },
              {
                title: 'Nhập kho',
                path: '/hk_trading/management-warehouse/import-warehouse',
                roles: ['HkGroup.QuanLyKho.NhapKho'],
              },
              {
                title: 'Xuất kho',
                path: '/404',
                roles: ['HkGroup.QuanLyKho.XuatKho'],
                children: [
                  {
                    title: 'Xuất phân phối',
                    path: '/hk_trading/management-warehouse/export/export-distribution',
                    roles: ['HkGroup.QuanLyKho.XuatKho.XuatPhanPhoi'],
                  },
                ],
              },
            ],
          },
          {
            title: 'Kế toán',
            path: '/404',
            roles: ['HkGroup'],
            children: [
              {
                title: 'Quản lý công nợ',
                path: '/hk_trading/debt',
                roles: ['HkGroup'],
              },
              {
                title: 'Quản lý thu chi',
                path: '/hk_trading/entry',
                roles: ['HkGroup'],
              },
            ],
          },

          // {
          //   title: 'Quản lý phân phối',
          //   path: '/404',
          //   roles: ['hkl4'],
          //   children: [
          //     {
          //       title: 'Kế hoạch phân phối chi tiết',
          //       path: '/hk_trading/distribution/detailed_plan',
          //       roles: ['hkl4'],
          //     },
          //   ],
          // },

          // {
          //   title: 'Kế hoạch nhập kho',
          //   path: '/404',
          //   roles: ['hkl4'],
          // },
          // {
          //   title: 'Kế hoạch phân phối',
          //   path: '/404',
          //   roles: ['hkl4'],
          // },
        ],
      },

    ],
  },
  {
    title: LocalStorage.get('tennant') || 'HK Care',
    roles: ["HkSalePoint", 'HkSalePoint.DanhSachSanPham', "HkSalePoint.QuanLyKho", "HkSalePoint.QuanLyBanHang", "HkSalePoint.QuanLyVanHanh", "HkSalePoint.BaoCao"],
    children: [
      {
        title: 'Danh sách sản phẩm',
        path: '/hk_care/product/list',
        roles: ['HkSalePoint.DanhSachSanPham'],
      },
      {
        title: 'Quản lý kho',
        path: '/404',
        roles: ["HkSalePoint.QuanLyKho"],
        children: [
          {
            title: 'Nhập kho',
            path: '/hk_care/warehouse/import/receipt',
            roles: ["HkSalePoint.QuanLyKho.NhapKho"],
          },
          {
            title: 'Xuất kho',
            path: '/404',
            roles: ["HkSalePoint.QuanLyKho.XuatKho"],
            children: [
              {
                title: 'Xuất hủy',
                path: '/hk_care/warehouse/export/cancel',
                roles: ["HkSalePoint.QuanLyKho.XuatKho.XuatHuy"],
              },
              {
                title: 'Xuất luân chuyển',
                path: '/hk_care/warehouse/export/circulation_invoice',
                roles: ["HkSalePoint.QuanLyKho.XuatKho.XuatLuanChuyen"],
              },
            ],
          },
          {
            title: 'Yêu cầu nhập hàng',
            path: '/hk_care/warehouse/request',
            roles: ['HkSalePoint.QuanLyKho.YeuCauNhapHang'],
          },
          {
            title: 'Kiểm kê kho',
            path: '/hk_care/warehouse/inventory_record',
            roles: ['HkSalePoint.QuanLyKho.KiemKeKho'],
          },
        ],
      },
      {
        title: 'Quản lý bán hàng',
        path: '/404',
        roles: ['HkSalePoint.QuanLyBanHang'],
        children: [
          {
            title: 'Bán hàng',
            path: '/hk_care/sales/order/create',
            roles: ['HkSalePoint.QuanLyBanHang.BanHang'],
          },
          {
            title: 'Danh sách hóa đơn',
            path: '/hk_care/sales/order',
            roles: ['HkSalePoint.QuanLyBanHang.DanhSachHoaDon'],
          },
          {
            title: 'Danh sách trả hàng',
            path: '/hk_care/sales/return-merchandise',
            // roles: ['HkSalePoint.QuanLyBanHang.DanhSachHoaDon'],
          },
        ],
      },
      {
        title: 'Quản lý vận hành',
        path: '/404',
        roles: ['HkSalePoint.QuanLyVanHanh'],
        children: [
          {
            title: 'Danh sách nhân viên',
            path: '/hk_care/operate/staff',
            roles: ['HkSalePoint.QuanLyVanHanh.DanhSachNhanVien'],
          },
          {
            title: 'Kế Toán ',
            path: '/hk_care/operate/accountant',
            roles: ['HkSalePoint.QuanLyVanHanh.DanhSachNhanVien'],
          },
          {
            title: 'Phân quyền',
            path: '/hk_care/operate/roles',
            roles: ['HkSalePoint.QuanLyVanHanh.PhanQuyen'],
          },
        ],
      },
      {
        title: 'Quản lý khách hàng',
        path: '/hk_care/customer',
        roles: ['HkSalePoint.QuanLyKhachHang'],
      },
      {
        title: 'Quản lý thu chi',
        path: '/hk_care/entry',
      },
      {
        title: 'Báo cáo',
        path: '/404',
        roles: ['HkSalePoint.BaoCao', 'HkSalePoint.BaoCao.BaoCaoDoanhThuTheoNhanVien'],
        children: [
          {
            title: 'Báo cáo doanh thu',
            path: '/hk_care/sales_statistical/sales_report',
            roles: ['HkSalePoint.BaoCao.BaoCaoDoanhThuTheoNhanVien'],
          },
          {
            title: 'Báo cáo nhập tồn kho',
            path: '/hk_care/sales_statistical/inventory_report',
            roles: ['HkSalePoint.BaoCao.BaoCaoNhapTonKho'],
          },
          {
            title: 'Báo cáo hàng cận date',
            path: '/hk_care/sales_statistical/search_near_date',
            roles: ['HkSalePoint.BaoCao.BaoCaoCanDate'],
          },
          {
            title: 'Báo cáo doanh thu theo giờ',
            path: '/hk_care/sales_statistical/inventorybyhours',
            roles: ['HkSalePoint.BaoCao.BaoCaoTheoGio'],
          },
          {
            title: 'Báo cáo doanh thu theo sản phẩm',
            path: '/hk_care/sales_statistical/report_inventory_by_product',
            roles: ['HkSalePoint.BaoCao.BaoCaoTheoSanPham'],
          },
          {
            title: 'Báo cáo doanh thu theo nhóm sản phẩm',
            path: '/hk_care/sales_statistical/report_inventory_group_product',
            roles: ['HkSalePoint.BaoCao.BaoCaoTheoNhomSanPham'],
          },
          {
            title: 'Báo cáo doanh thu theo kỳ',
            path: '/hk_care/sales_statistical/inventorybyperiod',
            roles: ['HkSalePoint.BaoCao.BaoCaoTheoKy'],
          },
          {
            title: 'Báo cáo thu chi theo ngày',
            path: '/hk_care/sales_statistical/daily_expenses',
            roles: ['HkSalePoint.BaoCao'],
          },
          {
            title: 'Báo cáo lãi lỗ',
            path: '/hk_care/sales_statistical/profit-and-loss-report',
            roles: ['HkSalePoint.BaoCao'],
          },
        ],
      },
    ],
  },

];

interface NavItemsProps {
  items: SectionItem[];
  pathname: string;
  depth?: number;
  roleUser: string[];
  isFirst: boolean;
}

const renderNavSectionItems = (props: NavItemsProps): JSX.Element => {
  const { depth = 0, items, roleUser, pathname, isFirst } = props;

  const itemsFiltered =
    depth === 0
      ? items.filter(
        (item) => item.roles && item.roles.some((r) => roleUser.includes(r))
      )
      : items;

  const start = isFirst;

  return (
    <List disablePadding>
      {itemsFiltered.reduce((acc: JSX.Element[], item, currentIndex) => {
        const { children, icon, info, path, title } = item;
        const key = `${title}-${depth}`;
        const partialMatch = pathname.startsWith(String(path));
        // const exactMatch = pathname === item.path;
        if (children) {
          const items = children.filter((item) => {
            const { roles } = item;
            return !roles || (roles && roles.some((r) => roleUser.includes(r)));
          });

          acc.push(
            <SidebarItem
              key={key}
              icon={icon}
              info={info}
              path={path}
              title={title}
              depth={depth}
              open={partialMatch || (start && currentIndex === 0)}
              active={partialMatch}
            >
              {renderNavSectionItems({
                depth: depth + 1,
                items,
                pathname,
                roleUser,
                isFirst: false,
              })}
            </SidebarItem>
          );
        } else {
          acc.push(
            <SidebarItem
              key={key}
              icon={icon}
              info={info}
              path={path}
              title={title}
              depth={depth}
              active={partialMatch}
            />
          );
        }

        return acc;
      }, [])}
    </List>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const sections = useMemo(() => getSections(), []);
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    roleService.getRolePermissionCurrent().then((res: any) => {
      if (!Array.isArray(res.data)) return;
      if (res.data.length !== auth.userRoles.length) {
        dispatch({ type: 'auth/logout' });
      }
    })
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      {renderNavSectionItems({
        items: sections,
        pathname: location.pathname,
        roleUser: auth.userRoles,
        isFirst: true,
      })}
    </Box>
  );
};

export default Sidebar;
