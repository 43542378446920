import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  FiltersParams,
  FormValueReturn,
} from 'interface/HKCare/ReturnMerchandise';
import returnMerchandise from 'services/HKCare/returnMerchandise';

export const getListReturnMerchandise = createAsyncThunk(
  'returnMerchandise/getList',
  async (filters: FiltersParams, { rejectWithValue }) => {
    try {
      const { data } = await returnMerchandise.getList(filters);
      if (data) {
        return {
          data: data.items || [],
          total: data.totalCount || 0,
        };
      }

      return rejectWithValue('Get data fail');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteReturnMerchandise = createAsyncThunk(
  'returnMerchandise/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      await returnMerchandise.delete(id);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListOrderSale = createAsyncThunk(
  'returnMerchandise/getListOrderSale',
  async (code: string, { rejectWithValue }) => {
    try {
      const { data } = await returnMerchandise.getListOder();
      if (data) {
        return {
          data: data || [],
        };
      }

      return rejectWithValue('Get data fail');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getInfoReturnMerchandise = createAsyncThunk(
  'returnMerchandise/getInfoReturn',
  async (code: number, { rejectWithValue }) => {
    try {
      const { data } = await returnMerchandise.getInfoReturn(code);
      if (data) {
        return data;
      }
      return rejectWithValue('Get data fail');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createReturnMerchandise = createAsyncThunk(
  'returnMerchandise/getListInfoProduct',
  async (params: FormValueReturn, { rejectWithValue }) => {
    try {
      const { data } = await returnMerchandise.create(params);
      if (data) {
        return data;
      }
      return rejectWithValue('Get data fail');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getReturnMerchandiseById = createAsyncThunk(
  'returnMerchandise/getDetail',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await returnMerchandise.getDetail(id);
      if (data) {
        return data;
      }
      return rejectWithValue('Get data fail');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListInfoProduct = createAsyncThunk(
  'returnMerchandise/getListInfoProduct',
  async (orderId: number, { rejectWithValue }) => {
    try {
      const { data } = await returnMerchandise.getListProductReturn(orderId);
      if (data) {
        return data;
      }
      return rejectWithValue('Get data fail');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateReturnMerchandise = createAsyncThunk(
  'returnMerchandise/update',
  async (value: any, { rejectWithValue }) => {
    try {
      const { data } = await returnMerchandise.update(value);
      if (data) {
        return data;
      }
      return rejectWithValue('Get data fail');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTenantDetail = createAsyncThunk(
  'getTenantDetail/byTenant',
  async (value: any, { rejectWithValue }) => {
    try {
      const { data } = await returnMerchandise.getTenantDetail();
      if (data) {
        return {
          data: data.data || {},
        };
      }

      return rejectWithValue('Get data fail');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
