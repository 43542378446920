import PrintIcon from '@mui/icons-material/Print';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { LoadingScreen } from 'components/common';
import { useNotification } from 'hooks';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import Barcode from 'react-barcode';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { getDataBarcode } from 'redux/slices/printBarcode';
import LocalStorage from 'utils/LocalStorage';
import './index.css';
// @ts-ignore
import Print from './print';

interface DataBarcode {
  productId: string;
  productName: string;
  barCode: string;
  amount: number;
}

const PrintBarcode = () => {
  const dispatch = useDispatch<any>();
  const setNotification = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<DataBarcode[]>([]);

  const [refs, setRefs] = useState();

  const fetchData = async () => {
    let dataPrint: any[] = JSON.parse(LocalStorage.get('PrintBarCode')) || [];
    if (_.isEmpty(dataPrint)) return;

    const { payload, error } = await dispatch(getDataBarcode(dataPrint));
    if (error) {
      setNotification({ error: 'Đang xử lý' });
      setLoading(false);
      return;
    }
    const array: DataBarcode[] = [];
    payload.data.forEach((element: any) => {
      for (let i = 0; i < element.amount; i++) {
        array.push(element);
      }
    });
    setData(array);
    setLoading(false);
  };

  const handlePrint = () => {
    Print('#retroReport');
  };

  useEffect(() => {
    fetchData();

    const handleTabClose = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      LocalStorage.remove('PrintBarCode');
      return;
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>In tem</title>
      </Helmet>
      <div>
        <Stack
          sx={{
            minHeight: '100vh',
            background: '#137b3e',
            position: 'relative',
          }}
          justifyContent="space-around"
          flexDirection="row"
          className="print"
        >
          <ReactToPrint
            trigger={() => (
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 20,
                  right: 20,
                  background: 'white',
                }}
                onClick={handlePrint}
              >
                <PrintIcon />
              </IconButton>
            )}
            // @ts-ignore
            content={() => refs}
          />

          <Box
            sx={{
              width: '7.2cm',
              background: 'white',
              right: '50%',
              minHeight: '100vh',
              color: 'black',
            }}
            id="retroReport"
            // @ts-ignore
            ref={(el) => setRefs(el)}
          >
            <Grid
              container
              sx={{
                padding: '0 0.1cm',
              }}
            >
              {data.map((item) => {
                return (
                  <Grid key={item.productId} xs={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '3.5cm',
                        height: '2.2cm',
                        mb: '2px',
                        overflow: 'hidden',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 'medium',
                          fontSize: '10px',
                          width: '3.3cm',
                          pl: '2px',
                          textAlign: 'center',
                        }}
                      >
                        Hệ thống HK CARE
                      </Typography>

                      <Box className="custom-barcode">
                        <Barcode
                          value={item.barCode}
                          displayValue={false}
                          height={4500}
                          width={110}
                        />
                      </Box>

                      <Typography className="product-name">
                        {item.productName}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Stack>
      </div>
    </Fragment>
  );
};

export default PrintBarcode;
