import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  EntitySelecter,
  FormFooter,
  FormHeader,
  FormLabel,
  FormPaperGrid,
} from 'components/Form';
import { useNotification } from 'hooks';
import { IAudience } from 'interface';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  createOrUpdateClosingWork,
  getDataClosingWorkingAsnyc,
  handleBackShiftHandover,
  handleCloseSiftHandover,
} from 'redux/slices/cashClosing';
import { getEntryAudience } from 'redux/slices/entry';
import { RootState } from 'redux/store';
import { numberFormat } from 'utils/numberFormat';
import * as yup from 'yup';
import { ObjectType } from '../../../Manage/Entry/TableData/util/constants';

interface FormValue {
  audienceType: number | null;
  audienceId: string | null;
  typeFinance: number | null;
  idTypeFinance: number | null;
  creatTime: Date | string | null;
  money: number;
  note: string;
}

const validationSchema = yup.object().shape({
  audienceType: yup
    .number()
    .nullable()
    .required('Vui lòng chọn loại đối tượng.')
    .default(2),
  audienceId: yup.string().nullable().required('Vui lòng chọn đối tượng.').default(null),
  typeFinance: yup.number().nullable().default(1),
  idTypeFinance: yup.number().default(10),
  creatTime: yup.date().nullable().default(null),
  money: yup.number().default(0),
  note: yup.string().default(''),
});

const Index = () => {
  const dispatch = useDispatch<any>();
  const { openShiftHandover } = useSelector(
    (state: RootState) => state.cashClosing
  );
  const setNotification = useNotification();
  const [audienceList, setAudienceList] = useState<IAudience[]>([]);

  const { control, setValue, handleSubmit, reset } = useForm<FormValue>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: validationSchema.getDefault(),
  });

  const audienceType = useWatch({
    control,
    name: 'audienceType',
  });
  const totalMoney = useWatch({
    control,
    name: 'money',
  });

  const fetchAudience = async () => {
    const response: any = await dispatch(
      getEntryAudience({
        audienceType: audienceType,
      })
    );

    setAudienceList(
      response.payload.audienceList.map((item: any) => ({
        name: item.name,
        id: item.userId,
      }))
    );
  };

  useEffect(() => {
    if (!openShiftHandover) return;
    fetchAudience();
  }, [audienceType, openShiftHandover]);

  const fetchData = async () => {
    const { payload, error } = await dispatch(
      getDataClosingWorkingAsnyc({ pageSize: 10, pageIndex: 1 })
    );
    if (error) {
      setNotification({ error: 'Hệ thống đang xử lý.' });
      return;
    }
    setValue('money', payload?.list?.[0]?.toTalMoney || 0);
  };

  useEffect(() => {
    if (!openShiftHandover) return;
    fetchData();
  }, [openShiftHandover]);

  const onSubmit = async (value: FormValue) => {
    const date = new Date();
    const params = { ...value };
    params.creatTime = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    const { error } = await dispatch(
      createOrUpdateClosingWork(params)
    );
    if (error) {
      setNotification({ error: 'Hệ thống đang xử lý.' });
      return;
    }
    setNotification({ message: 'Thành công.', severity: 'success' });
    reset(validationSchema.getDefault());
    dispatch(handleCloseSiftHandover());
  };

  const handleClose = () => {
    reset(validationSchema.getDefault());
    dispatch(handleCloseSiftHandover());
  };
  const handleBack = () => {
    reset(validationSchema.getDefault());
    dispatch(handleBackShiftHandover());
  };
  
  return (
    <Dialog
      open={openShiftHandover}
      maxWidth={'sm'}
      fullWidth
      onClose={handleClose}
    >
      <FormPaperGrid onSubmit={handleSubmit(onSubmit)}>
        <FormHeader sx={{ textAlign: 'center' }} title="BÀN GIAO CA MỚI" />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Tiền mặt bàn giao:{' '}
              <Typography component="span" color="primary.main">
                {numberFormat(totalMoney)}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel title="Loại đối tượng" required name="audienceType" />
            <EntitySelecter
              name="audienceType"
              control={control}
              options={ObjectType}
              renderLabel={(field) => field.name}
              renderValue="id"
              noOptionsText="Không tìm thấy loại đối tượng"
              onChangeSelect={() => {
                setValue('audienceId', null);
              }}
              placeholder="Chọn loại đối tượng"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel title="Đối tượng" required name="audienceId" />
            <EntitySelecter
              name="audienceId"
              control={control}
              options={audienceList}
              renderLabel={(field) => field.name}
              renderValue="id"
              noOptionsText="Không tìm thấy loại đối tượng"
              placeholder="Chọn đối tượng"
            />
          </Grid>
        </Grid>
        <FormFooter>
          <Button variant="outlined" onClick={handleBack}>
            Quay lại
          </Button>
          <LoadingButton type="submit">Xác nhận</LoadingButton>
        </FormFooter>
      </FormPaperGrid>
    </Dialog>
  );
};

export default Index;
