import { TextField } from '@mui/material';
import type { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { debounce } from 'lodash';

interface Props<T extends FieldValues> extends Omit<TextFieldProps, 'name'> {
  name: FieldPath<T>;
  setValue: UseFormSetValue<T>;
  value?: any | null | undefined;
  disabled?: boolean;
  inputRef?: any;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  type?: string;
  control: Control<T>;
  defaultValue?: number;
  errors?: string;
  onChangeValue?: (value: any) => void;
  onKeyDown?: (value: any) => void;
  InputProps?: any;
  onBlur?: (value: any) => void;
}

const ControllerNumberInput = <T extends FieldValues>(props: Props<T>) => {
  const {
    name,
    value,
    setValue,
    disabled,
    inputRef,
    defaultValue = 0,
    variant = 'outlined',
    type,
    control,
    inputProps,
    errors,
    onChangeValue,
    onKeyDown,
    InputProps,
    onBlur,
  } = props;

  if (type === 'percent') {
    return (
      <Controller
        render={({ field: { ref, ...others }, fieldState: { error } }) => {
          return (
            <NumberFormat
              fullWidth
              value={value}
              customInput={TextField}
              name={name}
              inputRef={(el: any) => inputRef?.(el) || ref}
              onValueChange={({ value: v }) =>
                // @ts-ignore
                setValue(name, v ? Number(v) : null)
              }
              onChange={(e: any) => {
                if (onChangeValue) {
                  let value = e.target.value;
                  onChangeValue(value ? Number(value.replace(/,/g, '')) : '');
                }
              }}
              onBlur={(event: any) => {
                if (onBlur) {
                  onBlur(event);
                  return;
                }
                let value = event.target.value;
                // @ts-ignore
                setValue(name, value ? Number(value.replace(/,/g, '')) : 0);
              }}
              allowedDecimalSeparators={[',', '.']}
              decimalScale={3}
              isNumericString
              thousandSeparator=","
              allowNegative={false}
              variant={variant}
              disabled={disabled}
              isAllowed={(values) => {
                const { floatValue } = values;
                // @ts-ignore
                return !floatValue || (floatValue <= 100 && floatValue >= 0);
              }}
              error={Boolean(error)}
              inputProps={inputProps}
              onKeyDown={(e: any) => {
                onKeyDown?.(e);
              }}
            />
          );
        }}
        name={name}
        control={control}
      />
    );
  }

  return (
    <Controller
      render={({ field: { ref, ...others }, fieldState: { error } }) => {
        return (
          <NumberFormat
            fullWidth
            customInput={TextField}
            value={value}
            defaultValue={defaultValue}
            onValueChange={({ value: v }) =>
              // @ts-ignore
              setValue(name, v ? Number(v) : null)
            }
            onChange={(e: any) => {
              let value = e.target.value;
              if (onChangeValue) {
                onChangeValue(value ? Number(value.replace(/,/g, '')) : '');
              }
            }}
            onBlur={(event: any) => {
              if (onBlur) {
                onBlur(event);
                return;
              }
              let value = event.target.value;
              // @ts-ignore
              setValue(name, value ? Number(value.replace(/,/g, '')) : 0);
            }}
            allowedDecimalSeparators={[',', '.']}
            decimalScale={0}
            isNumericString
            thousandSeparator=","
            allowNegative={false}
            disabled={disabled}
            error={Boolean(error) || Boolean(errors)}
            helperText={error?.message || errors}
            inputRef={(el: any) => inputRef?.(el) || ref}
            variant={variant}
            inputProps={inputProps}
            InputProps={InputProps}
            onKeyDown={(e: any) => {
              onKeyDown?.(e);
            }}
          />
        );
      }}
      name={name}
      control={control}
    />
  );
};

export default ControllerNumberInput;
