import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import {
  Badge,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import type { AppBarProps } from '@mui/material/AppBar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import LogoutConfirmDialog from 'components/common/LogoutConfirmDialog';
import { useNotification } from 'hooks';
import useMounted from 'hooks/useMounted';
import useNotificationMessage from 'hooks/useNotificationMessage';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'redux/slices';
import {
  checkDisplayConfirmDialog,
  handleOpenCashClosing,
  handleOpenConfirmShiftClosing,
} from 'redux/slices/cashClosing';
import { RootState } from 'redux/store';
import LocalStorage from 'utils/LocalStorage';
import CashClosing from 'views/HK_Care/CashClosing';
import PopperMessage from './PopperMessage';

interface Props extends AppBarProps {
  onToggleMobileSidebar: () => void;
  openDrawer: boolean;
  onToggleDrawer: () => void;
}

const DashboardNavbar: FC<Props> = (props) => {
  const mounted = useMounted();
  const navigate = useNavigate();
  const setNotification = useNotification();
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const { total } = useNotificationMessage();
  const dispatch = useDispatch<any>();
  const { username } = useSelector((state: RootState) => state.auth);
  const { onToggleMobileSidebar, openDrawer, onToggleDrawer } = props;
  const [openLogoutDialog, setOpenLogoutDialog] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const tennant = LocalStorage.get('tennant');

  const [anchorDropdownEl, setAnchorDropdownEl] =
    useState<HTMLButtonElement | null>(null);
  const handleOpenLogoutDialog = () => {
    setOpenLogoutDialog(true);
  };

  const handleCloseLogoutDialog = () => {
    if (mounted.current) {
      setOpenLogoutDialog(false);
    }
  };

  const handleLogout = async () => {
    await dispatch(logout());
    return navigate('/login');
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDropdown = () => {
    setAnchorDropdownEl(null);
    setOpenDropdown(false);
  };

  const handleOpenDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorDropdownEl(event.currentTarget);
    setOpenDropdown(true);
  };

  const handleCheckShiftClosing = async () => {
    const { payload, error } = await dispatch(checkDisplayConfirmDialog());
    if (error) {
      setNotification({ error: 'Hệ thống đang xử lý.' });
      return;
    }
    if (payload.display) {
      dispatch(handleOpenConfirmShiftClosing());
    } else {
      dispatch(handleOpenCashClosing());
    }
    setOpenDropdown(false);
  };

  return (
    <StyledAppBar open={openDrawer} elevation={0}>
      <Toolbar sx={{ minHeight: '55px !important' }}>
        <Hidden lgDown>
          <IconButton edge="start" onClick={onToggleDrawer}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton onClick={onToggleMobileSidebar}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden lgDown>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <IconButton onClick={handleClick}>
              <Badge color="error" badgeContent={total?.notSeen || 0}>
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Typography
              color="text.secondary"
              sx={{ cursor: 'pointer' }}
              onClick={handleOpenDropdown}
            >
              {username}
            </Typography>
            <IconButton onClick={handleOpenLogoutDialog}>
              <LogoutIcon sx={{ mr: 1 }} />
              <Typography color="text.secondary">Đăng xuất</Typography>
            </IconButton>
          </Stack>
          <LogoutConfirmDialog
            open={openLogoutDialog}
            onClose={handleCloseLogoutDialog}
            onSubmit={handleLogout}
            content={{
              label: 'Đăng xuất',
              icon: LogoutIcon,
            }}
          />
        </Hidden>
      </Toolbar>
      <Divider />
      <PopperMessage anchorEl={anchorEl} handleClose={handleClose} />
      <Menu
        id="basic-menu"
        anchorEl={anchorDropdownEl}
        open={openDropdown}
        onClose={handleCloseDropdown}
      >
        <MenuItem onClick={() => navigate('/user/profile')}>
          <ListItemIcon>
            <PortraitOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Thông tin cá nhân</ListItemText>
        </MenuItem>
        {tennant ? (
          <MenuItem onClick={handleCheckShiftClosing}>
            <ListItemIcon>
              <ReceiptLongOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Chốt tiền cuối ca</ListItemText>
          </MenuItem>
        ) : (
          void 0
        )}
      </Menu>
      <CashClosing />
    </StyledAppBar>
  );
};

interface StyledAppBarProps extends AppBarProps {
  open: boolean;
}

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop: string) => !['open'].includes(prop),
})<StyledAppBarProps>(({ theme, open }) => ({
  backgroundColor: theme.palette.background.paper,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: 280,
    width: 'calc(100% - 270px)',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  [theme.breakpoints.down('lg')]: {
    marginLeft: 'revert',
    width: '100%',
  },
}));

export default DashboardNavbar;
