/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { useNotification } from 'hooks';
import { FC, useEffect } from 'react';
import { createContext, useState } from 'react';
import { connectURL } from 'config';

import notificationService from 'services/notification.service';
import LocalStorage from 'utils/LocalStorage';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { RECIPIENT_ID } from 'constants/notification';
import { INotificationParams } from 'types/notification';
import { defaultFilters, defaultNotiFilters } from 'constants/defaultFilters';
import SplashScreen from 'components/common/SplashScreen';

export const NotificationMessageContext = createContext<any>(null);

if (process.env.NODE_ENV === 'development') {
  NotificationMessageContext.displayName = 'NotificationMessageContext';
}

interface ITotal {
  all: number;
  notSeen: number;
  seen: number;
}

const NotificationMessageProvider: FC = ({ children }) => {
  const auth = useSelector((state: RootState) => state.auth);
  const {isUpdateMessage} = useSelector((state: RootState) => state.cashClosing);
  
  const setNotification = useNotification();
  const [connectionRef, setConnectionRef] = useState<HubConnection>();
  const { username } = useSelector((state: RootState) => state.auth);
  const [message, setMessage] = useState<any>([]);
  const [messageNotSeen, setMessageNotSeen] = useState<any>([]);
  const [messageSeen, setMessageSeen] = useState<any>([]);
  const [total, setTotal] = useState<ITotal>({
    all: 0,
    notSeen: 0,
    seen: 0,
  });

  const [maxPageIndex, setMaxPageIndex] = useState<ITotal>({
    all: 0,
    notSeen: 0,
    seen: 0,
  });
  const [connection, setConnection] = useState<any>(null);
  const [tabNotification, setTabNotification] = useState<string>('all');

  const [filterParams, setFilterParams] = useState<INotificationParams>({
    ...defaultNotiFilters,
  });

  const [filterParamsNotSeen, setFilterParamsNotSeen] =
    useState<INotificationParams>({
      ...defaultNotiFilters,
    });

  const [filterParamsSeen, setFilterParamsSeen] = useState<INotificationParams>(
    {
      ...defaultNotiFilters,
    }
  );
  
  useEffect(() => {
	if(!auth.isAuthenticated){
		return;
	}
    const fetchApi = async () => {
      try {
        const response: any = await notificationService.getNotificationList(
          filterParams
        );
        const responseNotSeen: any =
          await notificationService.getNotificationNotRead(filterParamsNotSeen);

        const responseSeen: any = await notificationService.getNotificationRead(
          filterParamsSeen
        );
        if (response.status === 200) {
          setTotal((prev: ITotal) => ({
            ...prev,
            all: response.data.value.totalCount,
            notSeen: responseNotSeen?.data.value.totalCount,
            seen: responseSeen?.data.value.totalCount,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchApi();
  }, [total.all,filterParams, auth.isAuthenticated, isUpdateMessage]);
  

  //get list

  useEffect(() => {
	if(!auth.isAuthenticated){
		return;
	}
    const fetchNotificationAll = async () => {
      try {
        const res: any = await notificationService.getNotificationList(
          filterParams
        );
        if (res.status === 200) {
          setMessage(res.data.value.items ?? []);
          // setFilterParams((state: any) => ({
          //   ...state,
          //   pageIndex: state.pageIndex + 1,
          // }));
          // if (res.data.value.totalCount <= 10) {
          //   setMaxPageIndex((state: any) => ({
          //     ...state,
          //     all: 1,
          //   }));
          // } else {
          //   setMaxPageIndex((state: any) => ({
          //     ...state,
          //     all: Math.ceil(res.total / 10),
          //   }));
          // }
        }
      } catch (error) {
        console.log(error);
      }
    };
    const fetchNotificationNotSeen = async () => {
      try {
        const res: any = await notificationService.getNotificationNotRead(
          filterParamsNotSeen
        );

        if (res.status === 200) {
          setMessageNotSeen(res.data.value.items ?? []);
          // setFilterParamsNotSeen((state: any) => ({
          //   ...state,
          //   pageIndex: state.pageIndex + 1,
          // }));
          // if (res.data.value.totalCount <= 10) {
          //   setMaxPageIndex((state: any) => ({
          //     ...state,
          //     notSeen: 1,
          //   }));
          // } else {
          //   setMaxPageIndex((state: any) => ({
          //     ...state,
          //     notSeen: Math.ceil(res.total / 10),
          //   }));
          // }
        }
      } catch (error) {
        console.log(error);
      }
    };
    const fetchNotificationSeen = async () => {
      try {
        const res: any = await notificationService.getNotificationRead(
          filterParamsSeen
        );
        if (res.status === 200) {
          setMessageSeen(res.data.value.items ?? []);
          // setFilterParamsSeen((state: any) => ({
          //   ...state,
          //   pageIndex: state.pageIndex + 1,
          // }));
          // if (res.data.value.totalCount <= 10) {
          //   setMaxPageIndex((state: any) => ({
          //     ...state,
          //     seen: 1,
          //   }));
          // } else {
          //   setMaxPageIndex((state: any) => ({
          //     ...state,
          //     seen: Math.ceil(res.total / 10),
          //   }));
          // }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchNotificationAll();
    fetchNotificationNotSeen();
    fetchNotificationSeen();
  }, [total.all, filterParams, auth.isAuthenticated, isUpdateMessage]);

  const createHubConnection = () => {
    const con = new HubConnectionBuilder()
      .withUrl(`${connectURL}/app-hub`, {
        accessTokenFactory: () =>
          JSON.parse(localStorage.getItem('accessToken') || ''),
      })
      .withAutomaticReconnect()
      .build();

    setConnectionRef(con);
  };

  useEffect(() => {
    if(!auth.isAuthenticated) return;
    createHubConnection();
  }, [auth.isAuthenticated]);

  useEffect(() => {
    (async () => {
	    if(!auth.isAuthenticated) return;
      if (connectionRef) {
        try {
          const res: any = await notificationService.getNotificationList(
            filterParams
          );
          const resNotResponse: any =
            await notificationService.getNotificationNotRead(
              filterParamsNotSeen
            );
          connectionRef
            .start()
            .then(() => {
              connectionRef.on('ReceiveMessage', (data: string) => {
                const dataMessage = JSON.parse(data);
                setNotification({
                  message: dataMessage.content || 'Tôi đã nhận được thông báo',
                  severity: 'success',
                });
                setMessage([dataMessage, ...res.data.value.items]);
                setMessageNotSeen([
                  dataMessage,
                  ...resNotResponse.data.value.items,
                ]);
                setTotal((prev: ITotal) => ({
                  ...prev,
                  notSeen: prev.notSeen + 1,
                  all: prev.all + 1,
                }));
              });

              connectionRef.on('ReceiveRevokeToken', (data: string) => {
                LocalStorage.remove('accessToken');
                LocalStorage.remove('refreshToken');
                window.location.reload();
              });
            })
            .catch((error: any) => console.log(error));
        } catch (error) { }
      }

      return () => {
        connectionRef?.stop();
      };
    })();
  }, [connectionRef, auth.isAuthenticated]);

  return (
    <NotificationMessageContext.Provider
      value={{
        total,
        setTotal,
        message,
        setMessage,
        messageSeen,
        setMessageSeen,
        messageNotSeen,
        setMessageNotSeen,
        filterParams,
        setFilterParams,
        filterParamsNotSeen,
        setFilterParamsNotSeen,
        filterParamsSeen,
        setFilterParamsSeen,
        tabNotification,
        setTabNotification,
        maxPageIndex,
      }}
    >
      {children}
    </NotificationMessageContext.Provider>
  );
};

export { NotificationMessageContext as default, NotificationMessageProvider };
