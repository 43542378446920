import type { ContextValue } from 'contexts/NotificationContext';
import NotificationMessageContext from 'contexts/NotificationMessageContext';
import { useContext } from 'react';

const useNotificationMessage = (): any => {
  const notificationMessageContext = useContext(NotificationMessageContext);

  if (!notificationMessageContext) {
    throw new Error('Forgot to wrap component in NotificationProvider');
  }

  return notificationMessageContext;
};

export default useNotificationMessage;
