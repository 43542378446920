import { createAsyncThunk } from '@reduxjs/toolkit';
import printBarcodeService from 'services/printBarcode.service';

// interface typeFilters {
//   [key: string]: any;
// }

// tạo phiếu chi cho ca sau
export const getDataBarcode = createAsyncThunk(
  'barcode/getByData',
  async (payload: any[], { rejectWithValue }) => {
    try {
      const data = await printBarcodeService.getBarcodeToPrint(payload);
      if (data) {
        return {
          data: data.data || [],
        };
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
