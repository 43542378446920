import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormGroup } from '@mui/material';
import DropdownCustom from 'components/common/DropdownCustom';
import { EntitySelecter } from 'components/Form';
import { IOptions } from 'interface/supSynthesisPlan';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface Props {
  title: string;
  name: string;
  dataFilter: any;
  options: IOptions[];
  noOptionsText: string;
  placeholder: string;
  onFilter: (data: any) => void;
  loading?: boolean;
}

export default function DropdownEntitySelecter(props: Props) {
  const {
    title,
    name,
    onFilter,
    dataFilter,
    options,
    noOptionsText,
    placeholder = 'Nhà cung cấp',
    loading = false,
  } = props;
  const validationSchema = yup.object().shape({
    [name]: yup.string().nullable().default(null),
  });
  const [open, setOpen] = useState<boolean>(false);

  const { control, reset } = useForm<any>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: validationSchema.getDefault(),
  });

  const handleChange = (value: any) => {
    onFilter(value);
    setOpen(false);
  };

  useEffect(() => {
    reset({ ...dataFilter });
  }, [dataFilter]);

  return (
    <>
      <DropdownCustom title={title} open={open} setOpen={setOpen}>
        <Box sx={{ width: 400 }}>
          <FormGroup>
            <EntitySelecter
              loading={loading}
              name={name}
              control={control}
              options={options}
              renderLabel={(field) => field.label}
              noOptionsText={noOptionsText}
              placeholder={placeholder}
              onChangeSelect={handleChange}
            />
          </FormGroup>
        </Box>
      </DropdownCustom>
    </>
  );
}
