import axiosClient from 'api';
import { baseURL } from 'config';
import {
  FiltersParams,
  FormValueReturn,
} from 'interface/HKCare/ReturnMerchandise';
import DateFns from 'utils/DateFns';

class ReturnMerchandiseService {
  getList(data: FiltersParams) {
    return axiosClient.post(`${baseURL}/Return/Search`, {
      ...data,
      startDate: data.startDate
        ? DateFns.format(data.startDate, 'yyyy-MM-dd') + 'T00:00'
        : null,
      endDate: data.endDate
        ? DateFns.format(data.endDate, 'yyyy-MM-dd') + 'T23:59'
        : null,
    });
  }
  delete(id: string) {
    return axiosClient.delete(`${baseURL}/Return/Delete?id=${id}`);
  }
  create(params: FormValueReturn) {
    return axiosClient.post(`${baseURL}/Return/Create`, params);
  }
  getListOder() {
    return axiosClient.get(`${baseURL}/Return/Orders`);
  }
  getInfoReturn(code: number) {
    return axiosClient.get(`${baseURL}/Return/SetDataCreate?orderId=${code}`);
  }
  getListProductReturn(code: number) {
    return axiosClient.get(`${baseURL}/Return/SetDataUpdate?orderId=${code}`);
  }
  getDetail(id: string) {
    return axiosClient.get(`${baseURL}/Return/Read?id=${id}`);
  }
  update(data: any) {
    return axiosClient.put(
      `${baseURL}/Return/Update?id=${data.id}`,
      data.params
    );
  }
  getTenantDetail() {
    return axiosClient.get(`${baseURL}/GetCurrentTenant`);
  }
}

export default new ReturnMerchandiseService();
