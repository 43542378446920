import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { LinkButton, Scrollbar } from 'components/common';
import {
  TableHeader,
  TablePagination,
  TableSearchField,
  TableWrapper,
} from 'components/Table';
import { Cells } from 'components/Table/TableHeader';
import React from 'react';
import { FilterParams } from 'types';
import type { Dictionary } from 'types/common';
import TableBodyContent from './TableBodyContentTD';

interface Props<T> {
  title: string;
  searchPlaceholder: string;
  addLabel: string;
  cells: Cells<T>;
  filters?: FilterParams;
  setFilters?: (filters: FilterParams) => void;
  list: T[];
  totalRows: number;
  loading?: boolean;
  children?: React.ReactElement;
  noDataText?: string;
}

const TableData = <T extends Dictionary>(props: Props<T>) => {
  const {
    title,
    searchPlaceholder,
    addLabel,
    cells,
    filters,
    setFilters,
    list,
    totalRows,
    loading,
    children,
    noDataText,
  } = props;

  const handleSearch = (searchText: string) => {
    if (!filters || !setFilters) return;
    setFilters({
      ...filters,
      pageIndex: 1,
      searchText,
    });
  };

  const handleChangePage = (pageIndex: number) => {
    if (!filters || !setFilters) return;
    setFilters({
      ...filters,

      pageIndex,
    });
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    if (!filters || !setFilters) return;
    setFilters({
      ...filters,
      pageIndex: 1,
      pageSize: rowsPerPage,
    });
  };

  const handleOnSort = (value: keyof T) => {
    if (!filters || !setFilters) return;
    setFilters({
      ...filters,
      sortDirection: filters.sortDirection === 'asc' ? 'desc' : 'asc',
      sortBy: value as string,
    });
  };

  return (
    <TableWrapper
      sx={{
        height: 1,
        gridTemplateRows: 'auto auto 1fr',
      }}
      component={Paper}
    >
      <TableSearchField
        title={title}
        placeHolder={searchPlaceholder}
        onSearch={handleSearch}
        searchText={filters?.searchText || ''}
      >
        <LinkButton disabled={list.some((item) => item.status === 0)} variant="outlined" startIcon={<AddIcon />} to="create">
          {addLabel}
        </LinkButton>
      </TableSearchField>
      <Box sx={{ px: 2 }}>{children}</Box>
      <TableContainer sx={{ p: 1.5, maxHeight: '60vh' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 'max-content' }} size="small">
            <TableHeader
              cells={cells}
              onSort={handleOnSort}
              sortDirection={filters?.sortDirection || ''}
              sortBy={filters?.sortBy || ''}
            />
            <TableBodyContent
              total={list.length}
              loading={loading}
              colSpan={cells.length}
              noDataText={noDataText}
            >
              {list.map((item, index) => {
                const listKey = cells.map((item) => item.id);
                return (
                  <TableRow hover tabIndex={-1} key={index}>
                    {listKey.map((key, indexCell) => {
                      const render =
                        cells.find((x) => x.id === key)?.render || undefined;
                      const content = render
                        ? render(item[key], item)
                        : item[key];
                      return <TableCell key={indexCell}>{content}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBodyContent>
          </Table>
        </Scrollbar>
      </TableContainer>
      <TablePagination
        pageIndex={filters?.pageIndex || 1}
        totalPages={totalRows}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={filters?.pageSize || 10}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </TableWrapper>
  );
};

export default TableData;
