import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IMeasure, IRole, IRolePermission } from 'interface';
import measureService from 'services/measure.service';
import roleService from 'services/role.service';
import { FilterParams } from 'types';

interface IInitialState { }

const initialState: IInitialState = {};

export const getAllRole = createAsyncThunk(
  'role/getAll',
  async (filters: FilterParams, { rejectWithValue }) => {
    try {
      const { data } = await roleService.getAllRole(filters);

      if (data.items) {
        const roleList = data.items;
        const totalCount = data.totalCount;

        return {
          roleList,
          totalCount,
        };
      }

      return rejectWithValue('Error');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createRole = createAsyncThunk(
  'role/create',
  async (payload: IRolePermission, { rejectWithValue }) => {
    try {
      await roleService.create({
        ...payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRole = createAsyncThunk(
  'role/update',
  async (payload: IRolePermission, { rejectWithValue }) => {
    try {
      await roleService.update({
        ...payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const detailRole = createAsyncThunk(
  'role/detail',
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await roleService.detail(id);
      return res.data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteRole = createAsyncThunk(
  'role/delete',
  async (params: any, { rejectWithValue }) => {
    try {
      await roleService.delete(params.id);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {},
  extraReducers: (builder) => { },
});

export default roleSlice.reducer;
