import axiosClient from 'api';
import { baseURL } from 'config';
import { IMeasure } from 'interface';
import { FilterParams } from 'types';

class NotificationService {

    getNotificationList(params: any) {

        return axiosClient.get(`${baseURL}/v1-server/GetAllNotification?PageIndex=${params.pageIndex}&PageSize=${params.pageSize}&Offset=${10}`);
    }

    getNotificationRead(params: any) {


        return axiosClient.get(`${baseURL}/v1-server/GetNotificationRead?PageIndex=${params.pageIndex}&PageSize=${params.pageSize}&Offset=${10}`);
    }

    getNotificationNotRead(params: any) {
        return axiosClient.get(`${baseURL}/v1-server/GetNotificationNotRead?PageIndex=${params.pageIndex}&PageSize=${params.pageSize}&Offset=${10}`);
    }

    UpdateAsReadAll() {
        return axiosClient.post(`${baseURL}/v1-server/UpdateAsReadAll`);
    }

    UpdateAsReadWithId(notificationId: any) {
        return axiosClient.post(`${baseURL}/v1-server/UpdateAsReadByIdNotification?notificationId=${notificationId}`);
    }
}

export default new NotificationService();
