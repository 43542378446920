import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { Fragment } from 'react';
import formatDateTime from 'utils/dateTimeFormat';
import { numberFormat } from 'utils/numberFormat';
import { IncomeAndExpenses } from '../../utils/type';
interface Props {
  row: IncomeAndExpenses;
}

const ExpandRow = (props: Props) => {
  const { row } = props;
  return (
    <Fragment>
      <TableRow hover tabIndex={-1}>
        <TableCell colSpan={2}></TableCell>
        <TableCell>{numberFormat(row.toTalCash)}</TableCell>
        <TableCell>{numberFormat(row.totalBanking)}</TableCell>
        <TableCell>{numberFormat(row.totalExternalReceipts)}</TableCell>
        <TableCell>{numberFormat(row.totalExpenses)}</TableCell>
      </TableRow>
      {row?.items?.map((item, index) => {
        return (
          <TableRow key={index} hover tabIndex={-1}>
            <TableCell>{item.code}</TableCell>
            <TableCell>{formatDateTime(item.creationtime)}</TableCell>
            <TableCell>{numberFormat(item.cash)}</TableCell>
            <TableCell>{numberFormat(item.banking)}</TableCell>
            <TableCell>{numberFormat(item.externalReceipts)}</TableCell>
            <TableCell>{numberFormat(item.expenses)}</TableCell>
          </TableRow>
        );
      })}
    </Fragment>
  );
};

export default ExpandRow;


// 210