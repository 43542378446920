import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { FormHeader } from 'components/Form';
import { useDispatch, useSelector } from 'react-redux';
import { checkConfirmShiftClosing, handleCloseConfirmShiftClosing } from 'redux/slices/cashClosing';
import { RootState } from 'redux/store';

const ConfirmDialog = () => {
  const { openConfirmShiftClosing } = useSelector(
    (state: RootState) => state.cashClosing
  );
  const dispatch = useDispatch<any>();
  const handleConfirm = () => {
    dispatch(checkConfirmShiftClosing());
  };

  const handleClose = () => {
    dispatch(handleCloseConfirmShiftClosing());
  };
  return (
    <Dialog
      open={openConfirmShiftClosing}
      maxWidth="xs"
      fullWidth
      onClose={handleClose}
      scroll="body"
    >
      <FormHeader
        sx={{ textAlign: 'center', mt: 2 }}
        title="XÁC NHẬN CHỐT CA?"
      />
      <DialogContent>
        <Typography variant="subtitle1" gutterBottom>
          Bạn đã thực hiện chốt ca trong ngày hôm nay, bạn chắc chắn muốn thực
          hiện lại?
        </Typography>
      </DialogContent>
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2, py: 2 }}>
        <Stack direction="row" spacing={1}>
          <LoadingButton variant="outlined" onClick={handleClose}>
            Quay lại
          </LoadingButton>
          <LoadingButton onClick={handleConfirm}>Xác nhận</LoadingButton>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default ConfirmDialog;
