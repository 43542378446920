import { TableCell, TableRow } from '@mui/material';
import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { LoadingScreen } from 'components/common';
import type { FC } from 'react';

interface Props extends BoxProps {
  loading?: boolean;
  total: number;
  noDataText?: string | React.ReactNode;
  colSpan: number;
}

const TableBodyContent: FC<Props> = (props) => {
  const {
    loading,
    total,
    children,
    colSpan,
    noDataText = 'Không tìm thấy thông tin tìm kiếm',
  } = props;

  if (loading)
    return (
      <TableRow>
        <TableCell colSpan={colSpan}>
          <LoadingScreen />
        </TableCell>
      </TableRow>
    );

  if (total === 0) {
    return (
      <TableRow>
        <TableCell colSpan={colSpan}>
          <Box
            sx={{ display: 'grid', placeContent: 'center', minHeight: '40vh' }}
          >
            <Typography variant="h6" color="text.secondary">
              {noDataText}
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    );
  }

  return <>{children}</>;
};

export default TableBodyContent;
