import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { FormFooter, FormHeader, FormPaperGrid } from 'components/Form';
import { TablePagination } from 'components/Table';
import type { Cells } from 'components/Table/TableHeaderGroupHeader';
import TableHeaderGroupHeader from 'components/Table/TableHeaderGroupHeader';
import { Scrollbar } from 'components/common';
import { useNotification } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkFinanceByClosingWorkAsnyc,
  getDataClosingWorkingAsnyc,
  handleCloseCashClosing,
  handleOpenShiftHandover,
} from 'redux/slices/cashClosing';
import formatDateTime from 'utils/dateTimeFormat';
import { numberFormat } from 'utils/numberFormat';
import { IncomeAndExpenses } from '../../utils/type';
import useFilters from './utils/filters';
import _ from 'lodash';
//components
import ExpandRow from './ExpandRow';
import Figure from './Figure';
import { RootState } from 'redux/store';

const getCells = (): Cells<any> => [
  {
    id: 'id',
    label: 'Số phiếu/Hóa đơn',
    rowSpan: 2,
  },
  {
    id: 'productName',
    label: 'Thời gian',
    rowSpan: 2,
  },
  {
    id: 'unit',
    label: 'Doanh thu bán hàng/ trả hàng',
    colSpan: 2,
    align: 'center',
  },
  {
    id: 'unit',
    label: 'Thu - chi ngoài',
    colSpan: 2,
    align: 'center',
  },
];

const Index = () => {
  const dispatch = useDispatch<any>();
  const setNotification = useNotification();
  const cells = useMemo(() => getCells(), []);
  const { filters, handleChangePage, handleChangeRowsPerPage } = useFilters();
  const [data, setData] = useState<IncomeAndExpenses[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [idFinance, setIdFinance] = useState<number>(0);
  const { openCashClosing } = useSelector(
    (state: RootState) => state.cashClosing
  );

  const fetchData = async () => {
    const { payload, error } = await dispatch(
      getDataClosingWorkingAsnyc(filters)
    );
    if (error) {
      setNotification({ error: 'Hệ thống đang xử lý.' });
      return;
    }
    setTotal(payload.total || 0);
    setData(payload.list || []);
    setIdFinance(payload.list?.[0]?.idFinance || 0);
  };

  useEffect(() => {
    if (!openCashClosing) return;
    fetchData();
  }, [openCashClosing, filters]);

  const handleClose = () => {
    setData([]);
    dispatch(handleCloseCashClosing());
  };

  const handleConfirm = async () => {
    if (!idFinance) {
      setData([]);
      dispatch(handleOpenShiftHandover());
      return;
    }
    const { error } = await dispatch(
      checkFinanceByClosingWorkAsnyc(data?.[0]?.toTalMoney || 0)
    );
    if (error) {
      setNotification({ error: 'Hệ thống đang xử lý.' });
      return;
    }
    setNotification({ message: 'Thành công', severity: 'success' });
    handleClose();
  };

  return (
    <Dialog
      open={openCashClosing}
      maxWidth={'md'}
      fullWidth
      onClose={handleClose}
    >
      <FormPaperGrid>
        <FormHeader
          sx={{ textAlign: 'center' }}
          title="THU - CHI TRONG CA LÀM VIỆC"
        />
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Figure
                title="Điểm bán"
                content={data?.[0]?.nameSalePoint || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Figure
                title="Thời gian chốt ca"
                content={
                  data?.[0]?.creationtime
                    ? formatDateTime(data?.[0]?.creationtime)
                    : ''
                }
                width={220}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Figure
                title="Nhân viên"
                content={data?.[0]?.nameEmployee || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Figure
                title="Bàn giao cuối ca (Tiền mặt)"
                content={numberFormat(data?.[0]?.toTalMoney || 0)}
                width={220}
              />
            </Grid>
          </Grid>
          <TableContainer sx={{ mt: 2 }}>
            <Scrollbar>
              <Table sx={{ minWidth: 'max-content' }} size="small">
                <TableHeaderGroupHeader
                  cells={cells}
                  cells2={[
                    {
                      id: 'giveMoney',
                      label: 'Tiền mặt',
                    },
                    {
                      id: 'transfer',
                      label: 'Chuyển khoản',
                    },
                    {
                      id: 'giveMoney',
                      label: 'Thu ngoài',
                    },
                    {
                      id: 'transfer',
                      label: 'Chi ngoài',
                    },
                  ]}
                />
                <TableBody>
                  {data.map((item, index) => {
                    return <ExpandRow key={index} row={item} />;
                  })}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          <TablePagination
            pageIndex={filters.pageIndex}
            totalPages={total}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={filters.pageSize}
            rowsPerPageOptions={[10, 25, 50, 100]}
          />
        </Box>
        <FormFooter>
          <Button variant="outlined" onClick={handleClose}>
            Đóng
          </Button>
          <LoadingButton onClick={handleConfirm} disabled={_.isEmpty(data)}>
            Chốt bàn giao
          </LoadingButton>
        </FormFooter>
      </FormPaperGrid>
    </Dialog>
  );
};

export default Index;
