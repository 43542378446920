import axiosClient from 'api';
import axios from 'axios';
import { baseURL, connectURL } from 'config';
import { ISupplier } from 'interface';
import { FilterParams } from 'types';
import LocalStorage from 'utils/LocalStorage';

class SupplierService {
  getAll({ pageIndex, pageSize, sortBy, searchText }: FilterParams) {
    return axiosClient.get(`${baseURL}/supplier/search-all`, {
      params: {
        Keyword: searchText,
        Sorting: sortBy,
        SkipCount: (pageIndex - 1) * pageSize,
        MaxResultCount: pageSize,
      },
    });
  }

  getAllSupplier() {
    return axiosClient.get(`${baseURL}/supplier/GetSupplierActive`);
  }

  get(id: number) {
    return axiosClient.get(`${baseURL}/supplier/GetSupplierById/${id}`);
  }

  create(payload: ISupplier, files: File[] | object[]) {
    const data = { ...payload, bussinessLicense: files, active: 1 };

    return axiosClient.post(`${baseURL}/supplier/Create`, data);
  }

  update(payload: ISupplier, files: File[] | object[]) {
    const data = { ...payload, bussinessLicense: files };

    return axiosClient.put(`${baseURL}/supplier/Update/${payload.id}`, data);
  }

  delete(id: number) {
    return axiosClient.delete(`${baseURL}/supplier/${id}`);
  }

  changeStatus(id: number, status: 2 | 1) {
    return axiosClient.post(`${baseURL}/supplier/ChangeStatus`, null, {
      params: {
        supplierId: id,
        status,
      },
    });
  }
}

 export default new SupplierService();
