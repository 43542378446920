import { Button } from '@mui/material';
import React from 'react';

interface IProps {
  children: React.ReactNode;
  onClick: () => void;
}

const ButtonFilter = React.forwardRef<
  HTMLButtonElement,
  React.HTMLProps<HTMLButtonElement>
>((props, ref) => {
  const { children, onClick } = props;
  return (
    <Button
      sx={{
        backgroundColor: 'white !important',
        color: 'black',
        borderRadius: '4px',
        whiteSpace: 'nowrap',
      }}
      ref={ref}
      onClick={onClick}
    >
      {children}
    </Button>
  );
});

export default ButtonFilter;
