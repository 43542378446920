import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth';
import measureReducer from './measure';
import productReducer from './product';
import productGroupReducer from './productGroup';
import productListReducer from './productList';
import supplierReducer from './supplier';
import treatmentGroupReducer from './treatmentGroup';
import usageReducer from './usage';
import salesOrderReducer from './salesOrder';
import tenantReducer from './tenant';
import distributionExportReducer from './distributionExport';
import roleReducer from './role';
import profileReducer from './profile';
import entryReducer from './entry';
import cashClosingReducer from './cashClosing';

export * from './auth';

const rootReducer = combineReducers({
  auth: authReducer,
  measure: measureReducer,
  supplier: supplierReducer,
  usage: usageReducer,
  treatmentGroup: treatmentGroupReducer,
  productGroup: productGroupReducer,
  product: productReducer,
  productList: productListReducer,
  salesOrder: salesOrderReducer,
  tenant: tenantReducer,
  distributionExport: distributionExportReducer,
  role: roleReducer,
  profile: profileReducer,
  entry: entryReducer,
  cashClosing: cashClosingReducer,

});

export default rootReducer;
