import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import distributionExportService from 'services/distributionExport.service';

interface typeParams {

  [key: string]: any
}
interface IInitialState {}

const initialState: IInitialState = {};

export const updateSuggestPrice = createAsyncThunk(
  'distributionExport/updateSuggestPrice',
  async (params: any, { rejectWithValue }) => {
    try {
      const { data } = await distributionExportService.updateSuggestPrice(params);
      return {
        message: data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateExportNumber = createAsyncThunk(
    'distributionExport/updateExportNumber',
    async (params: any, { rejectWithValue }) => {
      
      try {
        const { data } = await distributionExportService.updateExportNumber(params);
        return {
          message: data,
        };
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  

const distributionExportSlice = createSlice({
  name: 'distributionExport',
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export default distributionExportSlice.reducer;
